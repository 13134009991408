var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            [
              _c("v-subheader", [_vm._v("mats")]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-list-item-group",
                [
                  _vm._l(_vm.links, function(link) {
                    return _c(
                      "v-list-item",
                      { key: link.to, attrs: { to: link.to } },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", {
                              domProps: { textContent: _vm._s(link.icon) }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [_vm._v(_vm._s(link.text))])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { on: { click: _vm.logout } },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-logout")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Изход")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-y-transition",
        [
          _vm.user
            ? _c(
                "v-app-bar",
                { attrs: { app: "", color: "green" } },
                [
                  _c("v-app-bar-nav-icon", {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.drawer = !_vm.drawer
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("v-toolbar-title", [_vm._v("mats")]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$root.user.username))]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", title: "Излизане" },
                      on: { click: _vm.logout }
                    },
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "v-fade-transition",
            { attrs: { mode: "out-in" } },
            [
              !_vm.user
                ? _c("Login", { on: { login: _vm.onLogin } })
                : _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [_c("router-view")],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { top: "", color: "error" },
          on: { input: _vm.onSnackChange },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c(
            "div",
            _vm._l(_vm.snackbarMessages, function(m) {
              return _c("div", [_vm._v(_vm._s(m))])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("Затвори")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }