var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": "40",
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c("v-text-field", {
                attrs: {
                  value: _vm.formattedDate,
                  label: _vm.label,
                  "append-icon": "mdi-calendar",
                  readonly: "",
                  rules: _vm.rules || [
                    function(t) {
                      return !!t || "Изберете дата"
                    }
                  ]
                },
                on: {
                  click: function($event) {
                    return _vm.open($event, on.click)
                  }
                }
              })
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c("v-date-picker", {
        attrs: {
          "first-day-of-week": "1",
          min: _vm.min,
          max: _vm.max,
          value: _vm.datepickerDate,
          events: _vm.events
        },
        on: {
          input: function($event) {
            ;(_vm.menu = false), _vm.$emit("input", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }