<template>
    <v-app>
        <v-navigation-drawer app clipped temporary v-model="drawer">
            <v-list>
                <v-subheader>mats</v-subheader>
                <v-divider></v-divider>
                <v-list-item-group>
                    <v-list-item v-for="link in links" :key="link.to" :to="link.to">
                        <v-list-item-icon>
                            <v-icon v-text="link.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ link.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Изход</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-slide-y-transition>
            <v-app-bar v-if="user" app color="green">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
                <v-toolbar-title>mats</v-toolbar-title>
                <v-spacer></v-spacer>
                <span>{{ $root.user.username }}</span>
                <v-btn icon title="Излизане" @click="logout">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>
        </v-slide-y-transition>

        <v-content>
            <v-fade-transition mode="out-in">
                <Login v-if="!user" @login="onLogin"></Login>

                <v-container fluid v-else>    
                    <router-view></router-view>
                </v-container>
            </v-fade-transition>
        </v-content>

        <v-snackbar
            v-model="snackbar"
            top
            color="error"
            @input="onSnackChange"
        >
            <div><div v-for="m in snackbarMessages">{{m}}</div></div>
            <v-btn @click="snackbar = false" dark text>Затвори</v-btn>
        </v-snackbar>
    </v-app>
</template>

<style>
    
</style>

<script>

import api from './webapi'

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './components/Home'
import Login from './components/Login'
import Tasks from './components/Tasks'
import Clients from './components/Clients'
import ClientEditor from './components/ClientEditor'
import Locations from './components/Locations'
import LocationEditor from './components/LocationEditor'
import Users from './components/Users'
import UserEditor from './components/UserEditor'
import Reports from './components/Reports'
import MatTypes from './components/MatTypes'
import MatTypeEditor from './components/MatTypeEditor'

import Vuetify from 'vuetify/lib'
import './maps/leaflet'
import "vuetify/dist/vuetify.min.css"
import bg from './locales/vuetify.bg'

import moment from 'moment'
moment.locale('bg');

Vue.use(Vuetify)
Vue.use(VueRouter);

const routes = [
    { path: '/', component: Home },
    { path: '/tasks', component: Tasks },
    { path: '/clients', component: Clients },
    { path: '/clients/new', component: ClientEditor },
    { path: '/clients/:id', component: ClientEditor, props: true },
    { path: '/clients/:client_id/locations', component: Locations, props: true },
    { path: '/clients/:client_id/locations/new', component: LocationEditor, props: true },
    { path: '/locations', component: Locations, props: { client_id: false } },
    { path: '/locations/new', component: LocationEditor },
    { path: '/locations/:id', component: LocationEditor, props: true },
    { path: '/users', component: Users},
    { path: '/users/new', component: UserEditor },
    { path: '/users/:id', component: UserEditor, props: true },
    { path: '/reports', component: Reports },
    { path: '/mat-types', component: MatTypes },
    { path: '/mat-types/new', component: MatTypeEditor },
    { path: '/mat-types/:id', component: MatTypeEditor, props: true },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    router.lastRoute = from;
    //console.log(router.lastRoute);
    next();
});

export default {
    name: 'MatsApplication',
    el: '#main',
    data() {
        return {
            drawer: false,
            user: null,
            department: null,
            snackbar: '',
            snackbarMessages: [],
            links: [
                {
                    to     : '/tasks',
                    icon   : 'mdi-calendar-check',
                    text   : 'Задачи',
                },
                {
                    to     : '/clients',
                    icon   : 'mdi-account-tie',
                    text   : 'Клиенти',
                },
                {
                    to     : '/locations',
                    icon     : 'mdi-map-marker',
                    text     : 'Обекти',
                },
                {
                    to     : '/reports',
                    icon   : 'mdi-chart-arc',
                    text   : 'Справки',
                },
                {
                    to     : '/users',
                    icon   : 'mdi-account',
                    text   : 'Потребители',
                },
                {
                    to     : '/mat-types',
                    icon   : 'mdi-barcode',
                    text   : 'Видове изтривалки',
                },
]
        };
    },
    methods: {
        showError(text) {
            this.snackbarMessages.push(text);
            this.snackbar = true;
        },
        onSnackChange() {
            if (!this.snackbar) {
                this.snackbarMessages = [];
            }
        },
        onWebApiError(err) {
            let msg;
            console.log(err);
            switch (err.response.status) {
                case 401:
                    // unauthorized
                    msg = this.user ?
                        "Изтекла сесия"
                        : "Неоторизиран достъп";
                    this.user = null;
                    break;
                case 404:
                    msg = "404 няма такъв обект";
                    break;
                case 406:
                    // used by Login, means wrong username/password
                    // Login component handles this
                    return;
                default:
                    msg = err.toString();
            }
            this.showError(msg
                    || "Грешка при връзка със сървъра");
        },
        onLogin(user) {
            this.department = user.department_id;
            this.user = user;
        },
        async logout() {
            await api.logout();
            this.user = null;
            router.push('/');
        }
    },
    mounted() {
        api.addErrorHandler(this.onWebApiError);
    },
    components: {
        Home,
        Tasks,
        Clients,
        ClientEditor,
        Locations,
        LocationEditor,
        Users,
        UserEditor,
        Login,
        Reports,
        MatTypes,
        MatTypeEditor
    },
    router,
    vuetify: new Vuetify({
        lang: {
            locales: { bg },
            current: 'bg'
        }
    })
}

</script>