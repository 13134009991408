import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=63a32926&"
import script from "./Clients.vue?vue&type=script&lang=js&"
export * from "./Clients.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VDataTable,VIcon,VSelect,VSpacer,VTextField,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Lang\\pCloudSync\\mats\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63a32926')) {
      api.createRecord('63a32926', component.options)
    } else {
      api.reload('63a32926', component.options)
    }
    module.hot.accept("./Clients.vue?vue&type=template&id=63a32926&", function () {
      api.rerender('63a32926', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Clients.vue"
export default component.exports