import axios from 'axios';
import queryString from 'query-string';

const BING_API_KEY = 'Am2cqC-asYQypZysJA41c69wfDO2AhFICR_rf0owbhHoo1d_zua6zXY1kqb-CGLP'

const api = axios.create({
    baseURL: '/api/'
});

var onerrorHandlers = [];

async function apiWrap(f) {
    try {
        return (await f()).data;
    }
    catch (e) {
        onerrorHandlers.forEach(h => h(e));
        throw e;
    }
}

export default {
    addErrorHandler: function(onerror) {
        onerrorHandlers.push(onerror);
    },

    clients: function() {
        return apiWrap(() => api.get('/clients'));
    },
    
    client: function(id) {
        return apiWrap(() => api.get(`/clients/${id}`));
    },

    clientLocations: function(clientId) {
        return apiWrap(() => api.get(`clients/${clientId}/locations`));
        
    },
    
    clientSave: function(client) {
        return apiWrap(() => client.id ? 
            api.put(`/clients/${client.id}`, client)
            :
            api.post('/clients', client));
    },

    clientPayment: function(clientId, date) {
        return apiWrap(() => api.put(`clients/${clientId}/payment`, {payment_date: date}));
    },

    tasksPending: function(days) {
        return apiWrap(() => api.get(`tasks/pending?${queryString.stringify({days})}`));
    },

    tasksDispatched: function(days) {
        return apiWrap(() => api.get(`tasks/dispatched?${queryString.stringify({days})}`));
    },

    tasksPayment: function(days) {
        return apiWrap(() => api.get(`tasks/payments?${queryString.stringify({days})}`));
    },
    
    
    locations: function() {
        return apiWrap(() => api.get('/locations'));
    },

    location: function(id) {
        return apiWrap(() => api.get(`/locations/${id}`));
    },

    locationSave: function(loc) {
        return apiWrap(() => loc.id ? 
            api.put(`/locations/${loc.id}`, loc)
            :
            api.post('/locations', loc));
    },

    locationDelete: function(id) {
        return apiWrap(() => api.delete(`/locations/${id}`));
    },

    locationService: function(id, serviceDate, matsNextServiceDates) {
        return apiWrap(() => api.post(`/locations/${id}/service`, {
            service_date: serviceDate,
            mats_next_service: matsNextServiceDates
        }));
    },

    autocomplete: function(...fields) {
        const query = queryString.stringify(fields.reduce((a,b)=> (a[b]=1,a),{}));
        return apiWrap(() => api.get(`/autocomplete?${query}`));
    },

    geocode: function(city, address) {
        const query = queryString.stringify({
            key: BING_API_KEY,
            q: `${address}, ${city}, Bulgaria`
        });
        return apiWrap(() => axios.get(`https://dev.virtualearth.net/REST/v1/Locations?${query}`)); 
    },

    users: function() {
        return apiWrap(() => api.get('/users'));
    },

    user: function(id) {
        return apiWrap(() => api.get(`/users/${id}`));
    },

    userSave: function(user) {
        return apiWrap(() => user.id ? 
            api.put(`/users/${user.id}`, user)
            :
            api.post('/users', user));
    },

    userDelete: function(id) {
        return apiWrap(() => api.delete(`/users/${id}`));
    },

    reportActive: function() {
        return apiWrap(() => api.get('/reports/active'));
    },

    reportHistory: function(from, to) {
        return apiWrap(() => api.get(`/reports/history/${from}/${to}`));
    },

    loginStatus: function() {
        return apiWrap(() => api.get('/auth/status'));
    },

    login: function(username, password) {
        return apiWrap(() => api.post('/auth/login', {username, password}));
    },

    logout: function() {
        return apiWrap(() => api.post('/auth/logout'));
    },

    matTypes: function() {
        return apiWrap(() => api.get('/mat-types'));
    },

    matType: function(id) {
        return apiWrap(() => api.get(`/mat-types/${id}`));
    },

    matTypeSave: function(mt) {
        return apiWrap(() => mt.id ? 
            api.put(`/mat-types/${mt.id}`, mt)
            :
            api.post('/mat-types', mt));
    },

    matTypeDelete: function(id) {
        return apiWrap(() => api.delete(`/mat-types/${id}`));
    },
}