<template>
    <v-container v-if="loginChecked">
        <v-row align="center" justify="center" style="height:80vh">
            <v-card style="width:400px" class="ma-6 elevation-7" shaped>
                <v-card-title>
                    Вписване
                </v-card-title>
                <v-form @submit.prevent="submit" ref="form">
                    <v-card-text>
                            <v-text-field label="Потребител" v-model="username" :rules="[s => nonempty(s) || 'Въведете потребител']" />
                            <v-text-field type="password" label="Парола" v-model="password" :rules="[s => nonempty(s) || 'Въведете парола']" />

                        <v-alert type="error" v-if="wrongCredentials">
                            Грешно име или парола
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" type="submit" @click="submit" :loading="loading">Вход</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-row>
    </v-container>
</template>



<script>
import api from '../webapi'
import * as R from 'ramda'

export default {
    props: [ "user" ],
    data() {
        return {
            username: '',
            password: '',
            loginChecked: false,
            loading: false,
            wrongCredentials: false
        }
    },
    methods: {
        async submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.wrongCredentials = false;
                try {
                    const user = await api.login(this.username, this.password);
                    this.$emit('login', user);
                }
                catch (e) {
                    if(e.response.status == 406) {
                        this.wrongCredentials = true;
                    }
                }

                this.loading = false;
            }
        },
        nonempty(s) {
            return !!(s && s.trim());
        }
    },
    async mounted() {
        const user = await api.loginStatus();
        if (user && !R.isEmpty(user))
            // already logged in
            this.$emit('login', user);
        else
            // not logged in, show the form
            this.loginChecked = true;
    }
}
</script>