var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-4 mx-auto",
      attrs: { "max-width": "600", loading: _vm.loading }
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.save($event)
                }
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Име",
                  rules: [
                    function(t) {
                      return !!t.trim() || "Въведете име"
                    }
                  ]
                },
                model: {
                  value: _vm.user.name,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "name", $$v)
                  },
                  expression: "user.name"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  label: "Потребител",
                  rules: [
                    function(t) {
                      return !!t.trim() || "Въведете име"
                    }
                  ]
                },
                model: {
                  value: _vm.user.username,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "username", $$v)
                  },
                  expression: "user.username"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                attrs: { label: "Отдел", items: _vm.departments },
                model: {
                  value: _vm.user.department_id,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "department_id", $$v)
                  },
                  expression: "user.department_id"
                }
              }),
              _vm._v(" "),
              _c("v-select", {
                attrs: { label: "Начален екран", items: _vm.homeRoutes },
                model: {
                  value: _vm.user.home_route,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "home_route", $$v)
                  },
                  expression: "user.home_route"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  type: "password",
                  label: "Парола",
                  rules: [_vm.validatePassword],
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.user.password,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "password", $$v)
                  },
                  expression: "user.password"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  type: "password",
                  label: "Парола повторно",
                  "validate-on-blur": ""
                },
                model: {
                  value: _vm.user.passwordRepeat,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "passwordRepeat", $$v)
                  },
                  expression: "user.passwordRepeat"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "error" },
                  on: { click: _vm.back }
                },
                [_vm._v("Откажи")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.saving },
                  on: { click: _vm.save }
                },
                [_vm._v("Запиши")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }