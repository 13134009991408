var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-tabs",
        { attrs: { dark: "", grow: "", "background-color": "primary" } },
        [
          _c("v-tab", [_vm._v("Текущи")]),
          _vm._v(" "),
          _c("v-tab", [_vm._v("Исторически")]),
          _vm._v(" "),
          _c("v-tab-item", [_c("v-card-text", [_c("VActive")], 1)], 1),
          _vm._v(" "),
          _c("v-tab-item", [_c("v-card-text", [_c("VHistory")], 1)], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }