import { render, staticRenderFns } from "./DispatchDialog.vue?vue&type=template&id=06dc1b24&"
import script from "./DispatchDialog.vue?vue&type=script&lang=js&"
export * from "./DispatchDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VForm,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("D:\\Lang\\pCloudSync\\mats\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06dc1b24')) {
      api.createRecord('06dc1b24', component.options)
    } else {
      api.reload('06dc1b24', component.options)
    }
    module.hot.accept("./DispatchDialog.vue?vue&type=template&id=06dc1b24&", function () {
      api.rerender('06dc1b24', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/tasks/DispatchDialog.vue"
export default component.exports