<template>
    <v-card class="pa-8">
        <v-data-table
                :headers="headers"
                :items="matTypes"
                :search="search"
                :loading="loading"
                sort-by="code">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="mr-8">
                        Видове изтривалки 
                    </v-toolbar-title>
                    <v-text-field hide-details single-line dense prepend-icon="mdi-magnify" clearable v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn fab color="error" dark to="/mat-types/new"><v-icon>mdi-plus</v-icon></v-btn>
                </v-toolbar>
            </template>
            
            <template v-slot:item.actions="{ item }">
                <v-btn small color="primary" title="Редактиране" :to="`/mat-types/${item.id}`" icon><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn small color="primary" title="Изтриване" @click="remove(item.id)" icon><v-icon>mdi-trash-can</v-icon></v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

import api from '../webapi'
import moment from 'moment'
import * as util from '../util'
import { PERIOD_TYPES_ITEMS } from './MatTypeEditor'

export default {
    name: 'MatTypes',
    data() {
        return {
            search: '',
            loading: true,
            matTypes: [],
            headers: [
                    { value:'code', text: 'Код' },
                    { value:'type', text: 'Вид' },
                    { value:'size', text: 'Размер' },
                    { value:'period', text: 'Период на обслужване' },
                    { value: 'actions', text: 'Действия', align: "right", sortable: false, filterable: false }
                ]
        };
    },
    methods: {
        async reload() {
            this.matTypes = Object.freeze(await api.matTypes());
            
            this.loading = false;
        },
        async remove(id) {
            if (confirm("Сигурни ли сте, че искате да изтриете този тип изтривалка?")) {
                await api.matTypeDelete(id);
                await this.reload();
            }
        }
    },
    mounted() {
        this.reload();
    }
}
</script>