<template>
    <v-card class="pa-8">
        <v-data-table
                :headers="headers"
                :items="filteredClients"
                :search="search"
                :loading="loading">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-text-field label="Търсене" clearable v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-select dense hide-details
                        style="max-width:200px"
                        class="mr-8"
                        label="Отдел"
                        :items="departments"
                        v-model="$root.department"></v-select>
                    <v-btn fab color="error" dark to="/clients/new"><v-icon>mdi-plus</v-icon></v-btn>
                </v-toolbar>
            </template>

            <template v-slot:item.department_id="{ item }">
                {{ departmentsById[item.department_id] }}
            </template>


            <template v-slot:item.actions="{ item }">
                    <v-btn small icon color="primary" title="Редактиране" :to="`/clients/${item.id}`"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn small icon color="primary" title="Обекти" :to="`/clients/${item.id}/locations`"><v-icon>mdi-database-marker</v-icon></v-btn>
                    <v-btn small icon color="primary" title="Изтриване" @click="remove(item.id)"><v-icon>mdi-trash-can</v-icon></v-btn>
            </template>
        </v-data-table>
    </v-card>
    
    
</template>

<script>

import api from '../webapi'
import * as util from '../util'

export default {
    name: 'Clients',
    data() {
        return {
            clients: [],
            search: '',
            loading: true,
            departments: [],
            departmentsById: {}
        };
    },
    computed: {
        headers() {
            return [].concat(
                { value:'name', text: 'Име' },
                !this.$root.department ? { value:'department_id', text: 'Отдел', filterable: false } : [],
                { value: 'actions', text: 'Действия', align: "right", sortable: false }
            );
        },
        filteredClients() {
            return this.clients.filter(c => !this.$root.department || c.department_id == this.$root.department);
        }
    },
    methods: {
        async reload() {
            const acLoading = api.autocomplete('departments');
            const clientsLoading = api.clients();

            const clients = await clientsLoading;
            const deps = (await acLoading).departments;

            this.clients = Object.freeze(await clientsLoading);
            this.departments = Object.freeze(util.makeDepartmentsSelect(deps));
            this.departmentsById = Object.freeze(util.itemsToByValue(deps));

            this.loading = false;
        },
        async remove(id) {
            if (confirm("Сигурни ли сте, че искате да изтриете този клиент?")) {
                await api.clientDelete(id)
                await this.reload();
            }
        }
    },
    mounted() {
        this.reload();
    }
}
</script>