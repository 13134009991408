var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "dispatched-tasks",
    attrs: {
      headers: _vm.headers,
      items: _vm.tasksComp.filteredDispatchedTasks,
      "disable-pagination": "",
      "hide-default-footer": "",
      loading: _vm.tasksComp.loading,
      "sort-by": "daysRemaining",
      "sort-desc": false,
      search: _vm.search,
      "no-data-text": "Няма потвърдени обекти"
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("VFilter", {
              attrs: { tasksComp: _vm.tasksComp, search: _vm.search },
              on: {
                "update:search": function($event) {
                  _vm.search = $event
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function(ref) {
          var items = ref.items
          var headers = ref.headers
          return _vm._l(items, function(item) {
            return _c(
              "tbody",
              { key: item.key },
              _vm._l(item.mats, function(mat, i) {
                return _c(
                  "tr",
                  { key: mat.id },
                  [
                    _vm._l(headers, function(header) {
                      return [
                        i == 0 || header.matprop
                          ? _c(
                              "td",
                              {
                                key: header.value,
                                class: { "mat-cell": header.matprop },
                                attrs: {
                                  rowspan: header.matprop
                                    ? ""
                                    : item.mats.length
                                }
                              },
                              [
                                header.value == "actions"
                                  ? [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            icon: "",
                                            title: "Покажи на картата"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.tasksComp.flyTo(
                                                item.latLng
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-map-marker")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  : header.value == "serviceDate"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "red--text": item.daysRemaining < 0,
                                            "orange--text":
                                              item.daysRemaining == 0,
                                            "blue--text":
                                              item.daysRemaining == 1
                                          }
                                        },
                                        [_vm._v(_vm._s(item.formattedDate))]
                                      )
                                    ]
                                  : header.value == "department_id"
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.tasksComp.departmentsById[
                                              item.location.department_id
                                            ]
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : header.value == "payment"
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.paymentMethods[
                                              mat.payment_method
                                            ]
                                          ) +
                                          " / " +
                                          _vm._s(
                                            _vm.paymentDocuments[
                                              mat.payment_document
                                            ]
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : header.matprop
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.path(header.value.split("."), {
                                              mat: mat
                                            })
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.path(
                                              header.value.split("."),
                                              item
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              }),
              0
            )
          })
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }