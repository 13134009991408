<template>
    <v-card max-width="600" class="pa-4 mx-auto" :loading="loading">
        <v-skeleton-loader type="card" v-if="loading" />
        <v-form ref="form" @submit.prevent="save" v-if="!loading">
            <v-text-field label="Име" v-model="user.name" :rules="[t => !!t.trim() || 'Въведете име']"/>
            <v-text-field label="Потребител" v-model="user.username" :rules="[t => !!t.trim() || 'Въведете име']"/>
            <v-select label="Отдел" :items="departments" v-model="user.department_id" />
            <v-select label="Начален екран" :items="homeRoutes" v-model="user.home_route" />
            <v-text-field type="password" label="Парола" v-model="user.password" :rules="[validatePassword]" validate-on-blur />
            <v-text-field type="password" label="Парола повторно" v-model="user.passwordRepeat" validate-on-blur />
        </v-form>
        <v-card-actions v-if="!loading">
            <v-btn class="mr-4" color="error" @click="back">Откажи</v-btn>
            <v-btn color="success" @click="save" :loading="saving">Запиши</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import api from '../webapi'
import * as util from '../util'

const HOME_ROUTES = [
    {value: '/tasks', text: 'Задачи'},
    {value: '/reports', text: 'Справки'},
]

export default {
    props: [ 'id' ],
    data() {
        return {
            loading: true,
            saving: false,
            homeRoutes: HOME_ROUTES,
            user: {
                name: '',
                username: '',
                password: '',
                passwordRepeat: '',
                home_route: HOME_ROUTES[0].value,
                department_id: null
            },
            departments: []
        };
    },
    methods: {
        async save() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                await api.userSave(this.user);
                this.back();
            }
        },
        back() {
            this.$router.push(this.$router.lastRoute || '/users');
        },
        validatePassword(p) {
            if (this.user.password != this.user.passwordRepeat)
                return 'Паролите не съвпадат';
            if (!this.user.password && !this.user.id)
                return 'Въведете парола';
            return true;
        }
    },
    async mounted() {
        this.departments = util.makeDepartmentsSelect((await api.autocomplete('departments')).departments);
        
        if (this.id) {
            Object.assign(this.user, await api.user(this.id));
        }
        this.loading = false;
    }
}
</script>