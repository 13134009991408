<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        this.$router.replace(this.$root.user.home_route);
    }
}
</script>