var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.isShown,
        callback: function($$v) {
          _vm.isShown = $$v
        },
        expression: "isShown"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4", attrs: { loading: _vm.loading } },
        [
          _c("v-card-title", [_vm._v("Oбслужване на обект")]),
          _vm._v(" "),
          _vm.loading
            ? _c("v-skeleton-loader", { attrs: { type: "card" } })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "v-form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.dispatch($event)
                    }
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("VDateInput", {
                        attrs: {
                          label: "Дата на обслужване",
                          min: _vm.today()
                        },
                        model: {
                          value: _vm.serviceDate,
                          callback: function($$v) {
                            _vm.serviceDate = $$v
                          },
                          expression: "serviceDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              _vm.isShown = false
                            }
                          }
                        },
                        [_vm._v("Откажи")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success", loading: _vm.saving },
                          on: { click: _vm.dispatch }
                        },
                        [_vm._v("Потвърди")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }