var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-4 mx-auto",
      attrs: { "max-width": "600", loading: _vm.loading }
    },
    [
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : [
            _c(
              "v-form",
              {
                ref: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.save($event)
                  }
                }
              },
              [
                _c("v-text-field", {
                  attrs: {
                    label: "Код",
                    rules: [
                      function(t) {
                        return !!t.trim() || "Въведете код"
                      }
                    ]
                  },
                  model: {
                    value: _vm.matType.code,
                    callback: function($$v) {
                      _vm.$set(_vm.matType, "code", $$v)
                    },
                    expression: "matType.code"
                  }
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    label: "Тип",
                    rules: [
                      function(t) {
                        return !!t.trim() || "Въведете тип"
                      }
                    ]
                  },
                  model: {
                    value: _vm.matType.type,
                    callback: function($$v) {
                      _vm.$set(_vm.matType, "type", $$v)
                    },
                    expression: "matType.type"
                  }
                }),
                _vm._v(" "),
                _c("v-combobox", {
                  attrs: {
                    label: "Размер",
                    rules: [
                      function(t) {
                        return !!t.trim() || "Изберете размер"
                      }
                    ],
                    items: _vm.matsizes
                  },
                  model: {
                    value: _vm.matType.size,
                    callback: function($$v) {
                      _vm.$set(_vm.matType, "size", $$v)
                    },
                    expression: "matType.size"
                  }
                }),
                _vm._v(" "),
                _c("v-combobox", {
                  attrs: {
                    label: "Период на осблужване",
                    rules: [
                      function(t) {
                        return !!t.trim() || "Изберете период на осблужване"
                      }
                    ],
                    items: _vm.periods
                  },
                  model: {
                    value: _vm.matType.period,
                    callback: function($$v) {
                      _vm.$set(_vm.matType, "period", $$v)
                    },
                    expression: "matType.period"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card-actions",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: { color: "error" },
                    on: { click: _vm.back }
                  },
                  [_vm._v("Откажи")]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "success", loading: _vm.saving },
                    on: { click: _vm.save }
                  },
                  [_vm._v("Запиши")]
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }