var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "pending-tasks",
    attrs: {
      headers: _vm.headers,
      items: _vm.tasksComp.filteredPendingTasks,
      "item-key": "key",
      "disable-pagination": "",
      "hide-default-footer": "",
      loading: _vm.tasksComp.loading,
      "sort-by": "daysRemaining",
      "sort-desc": false,
      search: _vm.search,
      "no-data-text": "Няма обекти за обслужване"
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("VFilter", {
              attrs: {
                tasksComp: _vm.tasksComp,
                search: _vm.search,
                distance: true
              },
              on: {
                "update:search": function($event) {
                  _vm.search = $event
                }
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function(ref) {
          var items = ref.items
          var headers = ref.headers
          return _vm._l(items, function(item) {
            return _c(
              "tbody",
              { key: item.key },
              _vm._l(item.mats, function(mat, i) {
                return _c(
                  "tr",
                  { key: mat.id },
                  [
                    _vm._l(headers, function(header) {
                      return [
                        i == 0 || header.matprop
                          ? _c(
                              "td",
                              {
                                key: header.value,
                                class: { "mat-cell": header.matprop },
                                attrs: {
                                  rowspan: header.matprop
                                    ? ""
                                    : item.mats.length
                                }
                              },
                              [
                                header.value == "actions"
                                  ? [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "end" } },
                                        [
                                          item.combinations.length
                                            ? _c(
                                                "v-edit-dialog",
                                                {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "input",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-simple-table",
                                                              [
                                                                _c("thead", [
                                                                  _c("tr", [
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "Пренасрочване"
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "Комбиниране с обект"
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "Разстояние"
                                                                      )
                                                                    ]),
                                                                    _vm._v(" "),
                                                                    _c("th", [
                                                                      _vm._v(
                                                                        "Действия"
                                                                      )
                                                                    ])
                                                                  ])
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "tbody",
                                                                  _vm._l(
                                                                    item.combinations,
                                                                    function(
                                                                      d
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            d
                                                                              .target
                                                                              .id
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                            " +
                                                                                  _vm._s(
                                                                                    item.formattedDate
                                                                                  ) +
                                                                                  " → " +
                                                                                  _vm._s(
                                                                                    d
                                                                                      .target
                                                                                      .formattedDate
                                                                                  ) +
                                                                                  "\n                                                        "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  d
                                                                                    .target
                                                                                    .location
                                                                                    .client
                                                                                    .name +
                                                                                    ", " +
                                                                                    d
                                                                                      .target
                                                                                      .location
                                                                                      .address +
                                                                                    ", " +
                                                                                    d
                                                                                      .target
                                                                                      .location
                                                                                      .city
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  d.distance
                                                                                    .human_readable()
                                                                                    .toString()
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    icon:
                                                                                      "",
                                                                                    title:
                                                                                      "Комбинирай"
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.tasksComp.dispatch(
                                                                                        item.id,
                                                                                        d.mats,
                                                                                        d
                                                                                          .target
                                                                                          .service_date
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-check-bold"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-btn",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    icon:
                                                                                      "",
                                                                                    title:
                                                                                      "Покажи обектите на картата"
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      ;(_vm.tasksComp.combinationMarkers = [
                                                                                        item,
                                                                                        d.target
                                                                                      ]),
                                                                                        _vm.tasksComp.flyTo(
                                                                                          item.latLng,
                                                                                          d
                                                                                            .target
                                                                                            .latLng
                                                                                        )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-map-marker-multiple"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        color: "accent",
                                                        content:
                                                          item.combinations
                                                            .length,
                                                        overlap: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            small: "",
                                                            icon: ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-link-variant"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                icon: "",
                                                title: "Потвърди"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.tasksComp.dispatch(
                                                    item.id,
                                                    item.mats,
                                                    item.serviceDate
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-check-bold")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                icon: "",
                                                title: "Покажи на картата"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.tasksComp.flyTo(
                                                    item.latLng
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-map-marker")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : header.value == "serviceDate"
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "red--text": item.daysRemaining < 0,
                                            "orange--text":
                                              item.daysRemaining == 0,
                                            "blue--text":
                                              item.daysRemaining == 1
                                          }
                                        },
                                        [_vm._v(_vm._s(item.formattedDate))]
                                      )
                                    ]
                                  : header.value == "department_id"
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.tasksComp.departmentsById[
                                              item.department_id
                                            ]
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : header.value == "payment"
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.paymentMethods[
                                              mat.payment_method
                                            ]
                                          ) +
                                          " / " +
                                          _vm._s(
                                            _vm.paymentDocuments[
                                              mat.payment_document
                                            ]
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : header.matprop
                                  ? [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.path(header.value.split("."), {
                                              mat: mat
                                            })
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  : [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.path(
                                              header.value.split("."),
                                              item
                                            )
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              }),
              0
            )
          })
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }