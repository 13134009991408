var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-4 mx-auto",
      attrs: { "max-width": "600", loading: _vm.loading }
    },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(this.id ? "Редактиране на клиент" : "Нов клиент"))
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Име",
                      rules: [
                        function(t) {
                          return !!t.trim() || "Въведете име"
                        }
                      ]
                    },
                    model: {
                      value: _vm.client.name,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "name", $$v)
                      },
                      expression: "client.name"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: { label: "Отговорен отдел", items: _vm.departments },
                    model: {
                      value: _vm.client.department_id,
                      callback: function($$v) {
                        _vm.$set(_vm.client, "department_id", $$v)
                      },
                      expression: "client.department_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "error" },
                  on: { click: _vm.back }
                },
                [_vm._v("Откажи")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.saving },
                  on: { click: _vm.save }
                },
                [_vm._v("Запиши")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }