<template>
    <v-input
        ref="periodDays"
        :rules="[validatePeriod]">
        <v-expansion-panels v-model="servicePeriodType" class="mb-4">
            <v-expansion-panel>
                <v-expansion-panel-header>Обслужване по дни от седмицата</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-item-group :multiple="true" v-model="byDow.days">
                        <v-item v-for="day in 5" :key="day" v-slot:default="{ active, toggle }"
                            :value="day">
                            <v-btn
                                @click="toggle()"
                                x-small
                                :color="active ? 'primary' : ''"
                            >{{ ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'][day - 1] }}</v-btn>
                        </v-item>
                    </v-item-group>
                    <v-select class="mt-4" dense solo
                        :items="weekPeriods" v-model="byDow.weekPeriod" />
                    <v-slide-y-transition origin="center">
                        <v-slider
                            v-if="byDow.weekPeriod > 1"
                            :label="'Отместване на периода в седмици: ' + byDow.weekOffset"
                            :min="0"
                            :max="byDow.weekPeriod - 1"
                            v-model="byDow.weekOffset"
                        />
                    </v-slide-y-transition>
                </v-expansion-panel-content>
            </v-expansion-panel>


            <v-expansion-panel>
                <v-expansion-panel-header>Обслужване по дата</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-input persistent-hint
                        hint="Ако месецът има по-малък брой дни от избраната дата се избира най-близката възможна в рамките на същия месец">
                        <v-item-group :multiple="true" v-model="byDate.days">
                            <table>
                                <tr v-for="week in 5" :key="week">
                                    <td v-for="day in 7" :key="day">
                                        <v-item v-slot:default="{ active, toggle }" v-if="7*(week-1) + day <= 31"
                                            :value="7*(week-1) + day">
                                            <v-btn
                                                @click="toggle()"
                                                x-small
                                                :color="active ? 'primary' : ''"
                                            >{{ 7*(week-1) + day}}</v-btn>
                                        </v-item>
                                    </td>
                                </tr>
                            </table>
                        </v-item-group>
                    </v-input>
                    <v-switch dense label="Ако датата се пада почивен ден използвай:"
                        hide-details
                        v-model="byDate.avoidWeekends"></v-switch>
                    <v-slide-y-transition origin="center">
                        <div class="mt-4" v-if="byDate.avoidWeekends">
                            <v-select solo dense :full-width="false"
                                v-model="byDate.avoidType"
                                persistent-hint
                                hint="Ако следващият/предходният работен ден се пада извън текущия месец се избира най-близкия възможен в рамките на същия месец"
                                :items="[{text:'Предходния работен ден', value:'prev'}, {text:'Следващия работен ден', value:'next'}]"></v-select>
                        </div>
                    </v-slide-y-transition>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-input>
</template>

<script>
import { PERIOD_TYPES, WEEKEND_AVOID_TYPES } from '../../util';
import * as R from 'ramda';

export default {
    props: [ "period" ],
    data() {
        return {
            PERIOD_TYPES: PERIOD_TYPES,
            WEEKEND_AVOID_TYPES: WEEKEND_AVOID_TYPES,
            type: PERIOD_TYPES.byDow,
            initted: false,
            byDow: {
                days: [],
                weekPeriod: 1,
                weekOffset: 0
            },
            byDate: {
                days: [],
                avoidWeekends: true,
                avoidType: 'prev'
            },
            weekPeriods: [
                { text: "Всяка седмица", value: 1},
                { text: "Всяка втора седмица", value: 2},
                { text: "Всяка трета седмица", value: 3},
                { text: "Всяка четвърта седмица", value: 4}
            ]
        }
    },
    methods: {
        validatePeriod() {
            if (!this.periodComputed || !this.periodComputed.days.length)
                return 'Изберете поне един ден от месеца за период';
            return true;
        }
    },
    mounted() {
        if (this.period && this.period.type) {
            this.type = this.period.type;
            const po = R.clone(this.period);
            delete po.type;
            this[this.type] = this.period;
        }
        else {
            this.$emit('update', this.periodComputed);
        }
        this.$nextTick(() => {
            this.initted = true;
        });
    },
    computed: {
        periodComputed: function() {
            return this.type ? R.assoc('type', this.type,
                this.type == this.PERIOD_TYPES.byDow ? this.byDow : this.byDate)
                : null;
        },

        servicePeriodType: {
            get() {
                return Object.values(this.PERIOD_TYPES).indexOf(this.type);
            },
            set(index) {
                this.type = Object.values(this.PERIOD_TYPES)[index];
            }
        },
    },

    watch: {
        periodComputed: function(period) {
            if (this.initted) {
                this.$refs.periodDays.validate();
                this.$emit('update', period);
            }
        }
    }
}
</script>
