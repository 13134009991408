<template>
    <div>
        <v-card>
            <v-data-table
                :headers="byCityHeaders"
                :items="byCityItems"
                disable-pagination
                hide-default-footer
                :loading="loading"
            >
                <template v-slot:top>
                    <v-container>
                        <div class="title">Справка по градове</div>
                    </v-container>
                </template>

                <template v-slot:body.append>
                    <tr style="font-weight:bold">
                        <td>Общо</td>
                        <td>{{ byCitySummray.clientsCount }}</td>
                        <td>{{ byCitySummray.locationsCount }}</td>
                        <td>{{ byCitySummray.mats }}</td>
                        <td>{{ byCitySummray.services }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

        <v-card class="mt-8">
            <v-data-table
                :headers="byMatHeaders"
                :items="byMatTypeItems"
                disable-pagination
                hide-default-footer
                :loading="loading"
            >
                <template v-slot:top>
                    <v-container>
                        <div class="title">Справка по видове изтривалки</div>
                    </v-container>
                </template>

                <template v-slot:item.matVariant="{ item }">
                    {{ rendMatType(item.matTypeId) }}
                </template>

                <template v-slot:item.cities="{ item }">
                    <v-chip v-for="(city, k) in item.cities" :key="k" x-small>
                        {{ k }}
                    </v-chip>
                </template>

                <template v-slot:body.append>
                    <tr style="font-weight:bold">
                        <td>Общо</td>
                        <td>{{ byMatTypeSummray.citiesCount }}</td>
                        <td>{{ byMatTypeSummray.clientsCount }}</td>
                        <td>{{ byMatTypeSummray.locationsCount }}</td>
                        <td>{{ byMatTypeSummray.mats }}</td>
                        <td>{{ byMatTypeSummray.services }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>

    </div>
</template>

<script>
import api from '../../webapi'
import * as R from 'ramda'
import * as util from '../../util'



export default {
    data() {
        return {
            mats: [],
            loading: true,
            byCityHeaders: [
                {value: 'city', text: 'Град' },
                {value: 'clientsCount', text: 'Клиенти' },
                {value: 'locationsCount', text: 'Обекти' },
                {value: 'mats', text: 'Изтривалки' },
                {value: 'services', text: 'Брой обслужваеми изтривалки за месец' }
            ],
            byMatHeaders: [
                {value: 'matVariant', text: 'Вид изтривалка' },
                {value: 'cities', text: 'Градове' },
                {value: 'clientsCount', text: 'Клиенти' },
                {value: 'locationsCount', text: 'Обекти' },
                {value: 'mats', text: 'Изтривалки' },
                {value: 'services', text: 'Брой обслужваеми изтривалки за месец' }
            ]
        };
    },
    computed: {
        byCityItems() {
            const matsByCity = R.groupBy(R.path(['location', 'city']), this.mats);
            const makeAccum = city => ({
                city,
                clients: {},
                locations: {},
                mats: 0,
                services: 0
            });

            const byCityAccum = R.mapObjIndexed(
                (mats, city) => R.reduce((acc, m) => {
                        acc.clients[m.location.client_id] = true;
                        acc.locations[m.location.id] = true;
                        acc.mats += m.count;
                        acc.services += m.count * util.numServicesInMonth(m.period);
                        return acc;
                    }, makeAccum(city), mats),
                matsByCity);

            return R.map(this.addSetCounts, R.values(byCityAccum));
        },

        byCitySummray() {
            return this.summarize(this.byCityItems);
        },


        byMatTypeItems() {
            const matsByType = R.groupBy(R.prop('mat_type_id'), this.mats);;
            const makeAccum = matTypeId => ({
                matTypeId,
                clients: {},
                cities: {},
                locations: {},
                mats: 0,
                services: 0
            });

            const byTypeAccum = R.mapObjIndexed(
                (mats, matTypeId) => R.reduce((acc, m) => {
                    acc.clients[m.location.client_id] = true;
                    acc.cities[m.location.city] = true;
                    acc.locations[m.location.id] = true;
                    acc.mats += m.count;
                    acc.services += m.count * util.numServicesInMonth(m.period);
                    return acc;
                }, makeAccum(matTypeId), mats),
                matsByType);

            return R.map(this.addSetCounts, R.values(byTypeAccum));
        },

        byMatTypeSummray() {
            return this.summarize(this.byMatTypeItems);
        },
    },
    methods: {
        flattenMats(locations) {
            return R.flatten(R.map(loc => R.map(R.assoc('location', loc), R.prop('mats', loc)), locations));
        },
        summarize(rows) {
            function sumProps(acc, obj) {
                R.forEachObjIndexed(
                    (val, key) => {
                        if (R.type(val) == 'Object')
                            acc[key] = R.mergeLeft(acc[key], val);
                        else
                            acc[key] += val;
                    },
                    obj);
                return acc;
            }

            return this.addSetCounts(R.reduce(
                (acc, row) => R.isEmpty(acc) ? R.clone(row) : sumProps(acc, row),
                [],
                rows));
        },
        addSetCounts(group) {
            R.forEachObjIndexed((val, key) => {
                if (R.type(val) == 'Object') {
                    //console.log(val, R.keys(val), R.keys(val).length);
                    group[key + 'Count'] = R.keys(val).length;
                }
            }, group);
            return group;
        },
        rendMatType(id) {
            const mt = this.matTypes[id];
            return `${mt.code}, ${mt.type}, ${mt.size}`;
        }
    },
    async mounted() {
        const mtLoading = api.matTypes();
        const repLoading = api.reportActive()

        this.matTypes = Object.freeze(util.objectsById(await mtLoading));
        this.mats = Object.freeze(this.flattenMats(await repLoading));
        this.loading = false;
    }
}
</script>