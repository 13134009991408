<template>
    <v-card max-width="600" class="pa-4 mx-auto" :loading="loading">
        <v-card-title>{{ this.id ? 'Редактиране на обект' : 'Нов обект'}}</v-card-title>
        <v-skeleton-loader type="card" v-if="loading" />
        <v-card-text v-if="!loading">
            <v-form ref="form" @submit.prevent="save">
                <v-autocomplete label="Клиент"
                    v-model="location.client_id"
                    :items="clients"
                    :rules="[t => !!t || 'Изберете клиент']"/>
                <v-select label="Отговорен отдел" v-model="location.department_id" :items="departments" />
                <v-combobox label="Град"
                                v-model="location.city"
                                :rules="[t => !!t.trim() || 'Изберете град']"
                                :items="cities" />
                <v-text-field type="text" label="Адрес" v-model="location.address" :rules="[t => !!t.trim() || 'Въведете адрес']" />




                <v-text-field label="Координати" @click="openMap" append-icon="mdi-map-marker" :value="coordinates"
                    :rules="[() => (!!(location.lat && location.lng) || 'Изберете координати')]" />
                <v-dialog
                    v-model="mapDialog"
                    width="96%"
                    >

                    <v-card height="90vh" class="d-flex align-start flex-column">

                            <l-map ref="map"
                                style="height: 100%; width: 100%"
                                @click="addMarker">
                                <l-control position="topright">
                                    <v-btn
                                            :disabled="!markerLatlng"
                                            @click="saveCoordinates"
                                            fab dark small color="success">
                                        <v-icon>mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn @click="mapDialog = false" fab dark small color="error"><v-icon>mdi-close</v-icon></v-btn>
                                </l-control>

                                <l-control position="bottomleft">
                                    <v-list dense>
                                        <v-subheader>
                                           {{ geocodesLoading ? "Търсене на адреси ..." : geocodes.length ? 'Открити адреси:' : 'Не са открити адреси' }}
                                        </v-subheader>

                                        <v-list-item
                                            v-for="(g, i) in geocodes"
                                            :key="i"
                                            @click="gotoGeocode(g.coords)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon>mdi-crosshairs-gps</v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="g.text"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>

                                </l-control>

                                <l-control-layers position="topleft"  ></l-control-layers>
                                <l-tile-layer v-for="tileProvider in leaflet.tileProviders"
                                    :key="tileProvider.name"
                                    :name="tileProvider.name"
                                    :visible="tileProvider.visible"
                                    :url="tileProvider.url"
                                    :attribution="tileProvider.attribution"
                                    layer-type="base"
                                ></l-tile-layer>

                                <l-marker v-for="(g, i) in geocodes" :key="i"
                                    :lat-lng="g.coords"
                                    :icon="circleIcon"
                                    @click="markerLatlng = g.coords"  />

                                <l-marker v-if="markerLatlng" :lat-lng="markerLatlng"></l-marker>
                            </l-map>

                    </v-card>
                </v-dialog>

                <v-switch v-model="location.active" label="Активен"></v-switch>

                <v-text-field
                    label="Толеранс за комбинации"
                    type="number"
                    min="0"
                    v-model="location.tolerance"
                    hint="±брой дни, за които ще се дава предложение за комбинация с друго обслужване"
                    :rules="[t => t !== '' && t >= 0 || 'Въведете брой дни']" />

                <v-textarea class="mt-8"
                    label="Забележка за обекта"
                    v-model="location.comment"
                    :rows="2"
                />


                <div style="position:relative" class="mt-4">
                    <v-scale-transition group tag="div">
                        <div class="mb-2" v-for="(mat, i) in location.mats" :key="mat._id">
                            <v-card class="yellow lighten-5">
                            <v-card-title>
                                Изтривалка {{i+1}}
                                <v-spacer/>
                                <v-scale-transition origin="center">
                                    <v-btn v-if="location.mats.length > 1" small icon @click="$delete(location.mats, i)"><v-icon>mdi-delete</v-icon></v-btn>
                                </v-scale-transition>
                            </v-card-title>
                            <v-card-text>
                                <v-autocomplete label="Код"
                                    :items="matTypeItems"
                                    v-model="mat.mat_type_id"
                                    :rules="[v => !!v || 'Изберете код']"/>
                                <v-combobox label="Цвят"
                                    v-model="mat.color"
                                    :rules="[t => !!t.trim() || 'Изберете цвят']"
                                    :items="matcolors" />

                                <v-text-field label="Брой"
                                    type="number"
                                    :min="1"
                                    v-model="mat.count"
                                    :rules="[v => v && v > 0 || 'Въведете брой']" />

                                <VPeriodInput @update="updateMatPeriod(mat, $event)" :period="mat.period" />

                                <VDateInput
                                    v-model="mat.next_service"
                                    label="Следващо обслужване"
                                    :min="today()"
                                    :events="makeIsPeriodDate(mat)"
                                    :rules="[d => !!d || 'Изберете дата']"
                                />
                                <v-alert
                                    v-if="mat.period && mat.period.days && mat.period.days.length && mat.next_service && !isServiceDay(mat.next_service, mat.period)"
                                    type="warning">
                                    Внимание: Избраната дата не принадлежи на периода на обслужване
                                </v-alert>


                                <v-radio-group label="Начин на плащане" v-model="mat.payment_method">
                                    <v-radio v-for="r in paymentMethods" :key="r.value" :value="r.value" :label="r.text" />
                                </v-radio-group>

                                <v-radio-group label="Платежен документ" v-model="mat.payment_document">
                                    <v-radio v-for="r in paymentDocuments" :key="r.value" :value="r.value" :label="r.text" />
                                </v-radio-group>


                                <v-textarea class="mt-8"
                                    label="Забележка"
                                    v-model="mat.comment"
                                    :rows="2"
                                />
                            </v-card-text>
                        </v-card>
                        </div>
                    </v-scale-transition>
                    <v-btn fab absolute bottom right small @click="addMat" color="error"><v-icon>mdi-plus</v-icon></v-btn>
                </div>

            </v-form>
        </v-card-text>
        <v-card-actions v-if="!loading">
            <v-btn class="mr-4" color="error" @click="back">Откажи</v-btn>
            <v-btn color="success" @click="save" :loading="saving">Запиши</v-btn>
        </v-card-actions>
    </v-card>


</template>

<script>

import api from '../webapi'
import leaflet from '../maps/leaflet'
import { circleIcon } from '../maps/leaflet'
import { latLng, latLngBounds } from 'leaflet'
import * as util from '../util'
import moment from 'moment'
import * as R from 'ramda'

import VDateInput from './inputs/DateInput'
import VPeriodInput from './inputs/PeriodInput'

export const paymentMethods = [
    {text: "По сметка", value: util.PAYMENT_METHODS.transfer},
    {text: "В брой", value: util.PAYMENT_METHODS.cash}
]

export const paymentDocuments = [
    {text: "Фактура", value: util.PAYMENT_DOCUMENTS.invoice},
    {text: "Стокова разписка", value: util.PAYMENT_DOCUMENTS.receipt}
]

export default {
    props: [ 'id', 'client_id' ],
    data() {
        return {
            newMatId: 0,
            mapDialog: false,
            departments: [],
            clients: [],
            matcolors: [],
            matsizes: [],
            cities: [],
            matTypes: [],
            nextServiceMenu: false,
            loading: true,
            saving: false,
            markerLatlng: null,

            geocodes: [],
            geocodesLoading: false,

            paymentDocuments,
            paymentMethods,


            location: {
                client_id: '',
                department_id: 1,
                active: true,
                lat: 0,
                lng: 0,
                city: '',
                address: '',
                tolerance: 3,
                comment: '',
                mats:[this.makeNewMat()]
            },
            circleIcon,
            leaflet
        };
    },
    computed: {
        coordinates() {
            if (this.location.lat && this.location.lng)
                return 'Въведени';
            else
                return 'Лиспват';
        },
        servicePeriodType: {
            get() {
                return ['byDow', 'byDate'].indexOf(this.location.period.type);
            },
            set(index) {
                this.location.period.type = ['byDow', 'byDate'][index];
            }
        },
        nextServiceFormatted() {
            return moment(this.location.next_service).format(util.DATE_FORMAT);
        },
        matTypeItems() {
            return this.matTypes.map(t =>
                ({
                    value: t.id,
                    text: `${t.code}, ${t.type}, ${t.size}, ${t.period}`
                })
            );
        }
    },
    methods: {
        nextNewMatId() {
            return `n${this.newMatId++}`;
        },
        makeNewMat() {
            return {_id: this.nextNewMatId(),
                    color: '',
                    count: 1,
                    comment: '',
                    period: null,
                    payment_method: util.PAYMENT_METHODS.transfer,
                    payment_document: util.PAYMENT_DOCUMENTS.invoice,
                    next_service: ''};
        },
        addMat() {
            this.location.mats.push(this.makeNewMat());
        },
        addMarker(e) {
            this.markerLatlng = e.latlng;
        },
        openMap() {
            this.mapDialog = true;
            this.geocodesLoading = true;

            this.$nextTick(async () => {
                this.$refs.map.mapObject.invalidateSize();
                if (this.location.lat && this.location.lng) {
                    this.markerLatlng = latLng(this.location.lat, this.location.lng);
                    //this.$refs.map.mapObject.setView(this.markerLatlng, 18);
                }

                // set default view to whole Bulgaria
                this.$refs.map.mapObject.setView([42.5929, 25.3120], 8);

                try {
                    this.setGeocodes((
                        this.location.city && this.location.address
                        && await api.geocode(this.location.city, this.location.address))
                            || null);
                }
                finally {
                    this.geocodesLoading = false;
                }
            });
        },
        saveCoordinates() {
            this.location.lat = this.markerLatlng.lat;
            this.location.lng = this.markerLatlng.lng;
            this.mapDialog = false;
        },
        today() {
            return util.formatDateDb(moment());
        },
        isServiceDay(date, period) {
            return util.isPeriodDate(moment(date), period);
        },
        setGeocodes(geocodes) {
            if (!geocodes) {
                geocodes = [];
            }
            else {
                try {
                    geocodes = geocodes.resourceSets[0].resources.map(r => ({
                        text: (r.address.addressLine ? `${r.address.addressLine}, ` : '')
                            + (r.address.postalCode ? `${r.address.postalCode} ` : '' )
                            + r.address.locality
                            + (r.address.locality != r.address.adminDistrict ? `, ${r.address.adminDistrict}` : ''),
                        coords: latLng(r.point.coordinates[0], r.point.coordinates[1])
                    }))
                }
                catch(e) {
                    console.log('Geocode parsing failed', e);
                    geocodes = [];
                }
            }

            this.geocodes = Object.freeze(geocodes);

            const points = [...this.geocodes.map(g => g.value), ...this.markerLatlng ? [this.markerLatlng] : []];
            if (points.length) {
                this.$refs.map.mapObject.fitBounds(latLngBounds(points).pad(.2));
            }
        },
        gotoGeocode(coords) {
            this.$refs.map.mapObject.flyTo(coords, 18);
        },
        save() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                setTimeout(async () => {
                    // this timeout is needed because there's a problem with v-combobox
                    // which does not update the model value immediately on blur
                    await api.locationSave(this.location);
                    this.back();
                }, 5);
            }
        },
        back() {
            this.$router.push(this.$router.lastRoute || '/locations');
        },
        makeIsPeriodDate(mat) {
            return date => mat.period && util.isPeriodDate(moment(date), mat.period);
        },
        updateMatPeriod(mat, $event) {
            mat.period = $event;

            // recalculate next service date
            if (mat.period) {
                var next = util.findNextPeriodDate(mat.period, moment());
                if (next) {
                    mat.next_service = util.formatDateDb(next);
                }
            }
        }
    },
    async mounted() {
        const acLoading = api.autocomplete('departments', 'clients', 'matcolors', 'matsizes', 'cities');

        if (this.id) {
            //const savedPeriod = this.location.period;
            this.location = await api.location(this.id);
            this.location.mats.forEach(m => m._id = m.id);
        }
        else if (this.client_id) {
            this.location.client_id = parseInt(this.client_id); // creating location for specific client
        }
        this.matTypes = Object.freeze(await api.matTypes());
        Object.assign(this, await acLoading);

        this.loading = false;
    },
    components: {
        VDateInput,
        VPeriodInput
    }
}
</script>