var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.byCityHeaders,
              items: _vm.byCityItems,
              "disable-pagination": "",
              "hide-default-footer": "",
              loading: _vm.loading
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-container", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Справка по градове")
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "body.append",
                fn: function() {
                  return [
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c("td", [_vm._v("Общо")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.byCitySummray.clientsCount))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.byCitySummray.locationsCount))
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.byCitySummray.mats))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.byCitySummray.services))])
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "mt-8" },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.byMatHeaders,
              items: _vm.byMatTypeItems,
              "disable-pagination": "",
              "hide-default-footer": "",
              loading: _vm.loading
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-container", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Справка по видове изтривалки")
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "item.matVariant",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.rendMatType(item.matTypeId)) +
                        "\n            "
                    )
                  ]
                }
              },
              {
                key: "item.cities",
                fn: function(ref) {
                  var item = ref.item
                  return _vm._l(item.cities, function(city, k) {
                    return _c("v-chip", { key: k, attrs: { "x-small": "" } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(k) +
                          "\n                "
                      )
                    ])
                  })
                }
              },
              {
                key: "body.append",
                fn: function() {
                  return [
                    _c("tr", { staticStyle: { "font-weight": "bold" } }, [
                      _c("td", [_vm._v("Общо")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.byMatTypeSummray.citiesCount))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.byMatTypeSummray.clientsCount))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.byMatTypeSummray.locationsCount))
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.byMatTypeSummray.mats))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.byMatTypeSummray.services))])
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }