<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :value="formattedDate"
                :label="label"
                append-icon="mdi-calendar"
                readonly
                v-on:click="open($event, on.click)"
                :rules="rules || [t => !!t || 'Изберете дата']"
            ></v-text-field>
        </template>
        <v-date-picker
            first-day-of-week="1"
            :min="min"
            :max="max"
            :value="datepickerDate"
            @input="menu = false, $emit('input', $event)"
            :events="events"
            >
        </v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment'
import * as util from '../../util'

export default {
    props: [ "value", "label", "min", "max", "events", "rules" ],
    data() {
        return {
            menu: false,
            datepickerDate: ''
        };
    },
    computed: {
        formattedDate() {
            return this.value ? moment(this.value).format(util.DATE_FORMAT) : '';
        }
    },
    methods: {
        open(e, onclick) {
            // This is required to force the datepicker reevaluate the Event dates.
            // We have to make the picker go to some other month, to rerender the
            // dates. Otherwise if the date is within the same month, the events
            // are not reevaluated
            const savedDate = this.value;
            this.datepickerDate = '1970-01-01';
            this.$nextTick(() => {
                this.datepickerDate = savedDate;
                onclick(e);
            });
        }
    }
}
</script>