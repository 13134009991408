var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-8" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.users,
          search: _vm.search,
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-toolbar-title", { staticClass: "mr-8" }, [
                      _vm._v("Потребители")
                    ]),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        "hide-details": "",
                        "single-line": "",
                        dense: "",
                        "prepend-icon": "mdi-magnify",
                        clearable: ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          fab: "",
                          color: "error",
                          dark: "",
                          to: "/users/new"
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.department.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      (item && item.depatment && item.department.name) ||
                        "Всички"
                    ) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "item.last_activity",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatDate(item.last_activity)) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      color: "primary",
                      title: "Редактиране",
                      to: "/users/" + item.id,
                      icon: ""
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-pencil")])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      color: "primary",
                      title: "Изтриване",
                      icon: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.remove(item.id)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-trash-can")])],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }