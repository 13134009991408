<template>
    <v-card max-width="600" class="pa-4 mx-auto" :loading="loading">
        <v-skeleton-loader type="card" v-if="loading" />
        <template v-else>
            <v-form ref="form" @submit.prevent="save">
                <v-text-field label="Код" v-model="matType.code" :rules="[t => !!t.trim() || 'Въведете код']"/>
                <v-text-field label="Тип" v-model="matType.type" :rules="[t => !!t.trim() || 'Въведете тип']"/>
                <v-combobox label="Размер"
                                        v-model="matType.size"
                                        :rules="[t => !!t.trim() || 'Изберете размер']"
                                        :items="matsizes" />
                <v-combobox label="Период на осблужване"
                                        v-model="matType.period"
                                        :rules="[t => !!t.trim() || 'Изберете период на осблужване']"
                                        :items="periods" />
            </v-form>
            <v-card-actions>
                <v-btn class="mr-4" color="error" @click="back">Откажи</v-btn>
                <v-btn color="success" @click="save" :loading="saving">Запиши</v-btn>
            </v-card-actions>
        </template>
    </v-card>
</template>

<script>

import api from '../webapi'
import * as util from '../util'


export default {
    props: [ 'id' ],
    data() {
        return {
            loading: true,
            saving: false,
            matType: {
                code: '',
                type: '',
                size: '',
                period: ''
            },
            periods: []
        };
    },
    methods: {
        save() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                setTimeout(async () => {
                    // this timeout is needed because there's a problem with v-combobox
                    // which does not update the model value immediately on blur
                    await api.matTypeSave(this.matType);
                    this.back();
                }, 5);
            }
        },
        back() {
            this.$router.push('/mat-types');
        }
    },
    async mounted() {
        const acLoading = api.autocomplete('periods', 'matsizes');
        if (this.id) {
            Object.assign(this.matType, await api.matType(this.id));
        }
        Object.assign(this, await acLoading);
        this.loading = false;
    }
}
</script>