<template>
    <v-card>
        <v-tabs dark grow background-color="primary">
           
            <v-tab>Текущи</v-tab>
            <v-tab>Исторически</v-tab>

            <v-tab-item>
                <v-card-text>
                    <VActive />
                </v-card-text>
            </v-tab-item>
            
            <v-tab-item>
                <v-card-text>
                    <VHistory />
                </v-card-text>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import VActive from './reports/Active'
import VHistory from './reports/History'

export default {
    name: 'Reports',
    components: {
        VActive,
        VHistory
    }
}
</script>