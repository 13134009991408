<template>
    <v-card class="pa-8">
        <v-data-table
                :headers="headers"
                :items="filteredLocations"
                :search="search"
                :loading="loading">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="mr-8">
                        Обекти 
                        {{ client ? `на клиент "${client.name}"` : '' }}
                    </v-toolbar-title>
                    <v-text-field hide-details single-line dense prepend-icon="mdi-magnify" clearable v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-select hide-details dense
                        style="max-width:200px"
                        class="mr-8"
                        label="Отдел"
                        :items="departments"
                        v-model="$root.department"></v-select>
                    <v-btn fab color="error" dark :to="client_id ? `/clients/${client_id}/locations/new` : '/locations/new'"><v-icon>mdi-plus</v-icon></v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.department_id="{ item }">
                {{ departmentsById[item.department_id] }}
            </template>
            <template v-slot:item.active="{ item }">
                {{ item.active ? "Да" : "Не" }}
            </template>
            <template v-slot:item.next_service="{ item }">
                {{ formatDate(item.next_service) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn small color="primary" title="Редактиране" :to="`/locations/${item.id}`" icon><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn small color="primary" title="Изтриване" @click="remove(item.id)" icon><v-icon>mdi-trash-can</v-icon></v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

import api from '../webapi'
import moment from 'moment'
import * as util from '../util'

export default {
    name: 'Locations',
    props: ['client_id'],
    data() {
        return {
            search: '',
            loading: true,
            departments: [],
            departmentsById: {},
            client: null,
            locations: []
        };
    },
    computed: {
        headers() {
            return [].concat(
                !this.client_id ? { value:'client.name', text: 'Клиент' } : [],
                !this.$root.department ? { value:'department_id', text: 'Отдел', filterable: false } : [],
                [
                    { value:'active', text: 'Активен', filterable: false },
                    { value:'city', text: 'Град' },
                    { value:'address', text: 'Адрес', sortable: false },
                    { value:'next_service', text: 'Следващо обслужване', filterable: false },
                    { value: 'actions', text: 'Действия', align: "right", sortable: false, filterable: false }
                ]);
        },
        filteredLocations() {
            return this.locations.filter(l => !this.$root.department || l.department_id == this.$root.department);
        }
    },
    watch: {
        client_id() {
            this.loading = true;
            this.reload();
        }
    },
    methods: {
        async reload() {
            const acLoading = api.autocomplete('departments');
            const locsLoading = this.client_id ? api.clientLocations(this.client_id) : api.locations();
            const clientLoading = this.client_id ? api.client(this.client_id) : Promise.resolve(null);
            
            const locs = await locsLoading;
            const deps = (await acLoading).departments;
            const client = await clientLoading;

            this.locations = Object.freeze(locs);
            this.client = Object.freeze(client);
            this.departments = Object.freeze(util.makeDepartmentsSelect(deps));
            this.departmentsById = Object.freeze(util.itemsToByValue(deps));
            
            this.loading = false;
        },
        async remove(id) {
            if (confirm("Сигурни ли сте, че искате да изтриете този обект?")) {
                await api.locationDelete(id);
                await this.reload();
            }
        },
        formatDate: util.formatDate
    },
    mounted() {
        this.reload();
    }
}
</script>