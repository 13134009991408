<template>
    <v-row>
        <v-col cols="12" sm="6" lg="3">
            <v-text-field hide-details single-line dense prepend-icon="mdi-magnify" clearable
                :value="search"
                @input="$emit('update:search', $event)"
                ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" lg="2">
            <v-select dense hide-details
                style="max-width:200px"
                label="Отдел"
                :items="tasksComp.departments"
                v-model="$root.department"></v-select>
        </v-col>
        <v-col v-if="distance" cols="6" sm="3" lg="2">
            <v-text-field type="number" hide-details dense prepend-icon="mdi-map-marker-distance"
                label="Максимално разстояние за комбинации"
                v-model="tasksComp.maxDistance"
                min="0"
                ></v-text-field>
        </v-col>
        <v-col cols="12" lg="5">
            <v-slider :min="0" dense single-line hide-details
                    :max="tasksComp.maxDays"
                    v-model="tasksComp.numDays"
                    :label="`Показвай следващите ${tasksComp.numDays} ${tasksComp.daysWord(tasksComp.numDays)}`"></v-slider>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: [ "tasksComp", "search", "distance" ]
}
</script>