import Vue from 'vue';
import { LMap, LTileLayer, LMarker, LControl, LControlLayers, LPopup } from 'vue2-leaflet';
import { Icon, latLngBounds } from 'leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'
import './svg-icon'

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css'

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-marker-cluster', LMarkerCluster);
Vue.component('l-control', LControl)
Vue.component('l-control-layers', LControlLayers)
Vue.component('l-popup', LPopup)

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export const circleIcon = L.icon({
    iconUrl: require('!url-loader!./circle.svg').default,
    iconSize: [30, 30],
    iconAnchor: [15, 15]
});

export const defaultBounds = latLngBounds([44.3, 22.3], [41, 28.5]);

export default {
  url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
  tileProviders: [
    {
      name: 'Пътна',
      visible: true,
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    {
      name: 'Сателитна',
      visible: false,
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }
  ]
}