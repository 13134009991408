var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loginChecked
    ? _c(
        "v-container",
        [
          _c(
            "v-row",
            {
              staticStyle: { height: "80vh" },
              attrs: { align: "center", justify: "center" }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-6 elevation-7",
                  staticStyle: { width: "400px" },
                  attrs: { shaped: "" }
                },
                [
                  _c("v-card-title", [
                    _vm._v("\n                Вписване\n            ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Потребител",
                              rules: [
                                function(s) {
                                  return (
                                    _vm.nonempty(s) || "Въведете потребител"
                                  )
                                }
                              ]
                            },
                            model: {
                              value: _vm.username,
                              callback: function($$v) {
                                _vm.username = $$v
                              },
                              expression: "username"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              type: "password",
                              label: "Парола",
                              rules: [
                                function(s) {
                                  return _vm.nonempty(s) || "Въведете парола"
                                }
                              ]
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _vm._v(" "),
                          _vm.wrongCredentials
                            ? _c("v-alert", { attrs: { type: "error" } }, [
                                _vm._v(
                                  "\n                        Грешно име или парола\n                    "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                type: "submit",
                                loading: _vm.loading
                              },
                              on: { click: _vm.submit }
                            },
                            [_vm._v("Вход")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }