<template>
    <v-card class="pa-8">
        <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
                :loading="loading">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="mr-8">Потребители</v-toolbar-title>
                    <v-text-field hide-details single-line dense prepend-icon="mdi-magnify" clearable v-model="search"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn fab color="error" dark :to="'/users/new'"><v-icon>mdi-plus</v-icon></v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.department.name="{ item }">
                {{ (item && item.depatment && item.department.name) || "Всички" }}
            </template>
            <template v-slot:item.last_activity="{ item }">
                {{ formatDate(item.last_activity) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn small color="primary" title="Редактиране" :to="`/users/${item.id}`" icon><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn small color="primary" title="Изтриване" @click="remove(item.id)" icon><v-icon>mdi-trash-can</v-icon></v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import api from '../webapi'
import * as util from '../util'

export default {
    data() {
        return {
            loading: true,
            users: [],
            search: '',
            headers: [
                { value:'name', text: 'Име' },
                { value:'username', text: 'Потребител' },
                { value:'department.name', text: 'Отдел' },
                { value:'last_activity', text: 'Последна активност', filterable: false },
                { value: 'actions', text: 'Действия', align: "right", sortable: false, filterable: false }
            ]
        }
    },
    methods: {
        formatDate: util.formatDate,
        async remove(id) {
            if (confirm("Сигурни ли сте, че искате да изтриете този потребител?")) {
                await api.userDelete(id);
                await this.reload();
            }
        },
        async reload() {
            this.users = Object.freeze(await api.users());
            this.loading = false;
        }
    },
    mounted() {
        this.reload();
    }
}
</script>