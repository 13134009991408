<template>
    <div>
        <VDispatchDialog
            :tasksComp="self"
            ref="dispatchDialog"
            @dispatched="loading=true,reload()"
            ></VDispatchDialog>

        <v-card>    
            <v-tabs dark grow background-color="primary" v-model="tab">
                <v-tab><v-badge color="warning" inline :content="filteredPendingTasks.length.toString()">За обслужване</v-badge></v-tab>
                <v-tab><v-badge color="warning" inline :content="filteredDispatchedTasks.length.toString()">Потвърдени</v-badge></v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item>
                    <v-card-text>
                        <VPending :tasksComp="self"></VPending>
                    </v-card-text>
                </v-tab-item>
                
                <v-tab-item>
                    <v-card-text>
                        <VDispatched :tasksComp="self"></VDispatched>
                    </v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-card>




        <v-btn  fab fixed bottom right color="green" dark @click="showMap"><v-icon>mdi-map</v-icon></v-btn>

        <v-bottom-sheet v-model="mapOpen" eager hide-overlay persistent no-click-animation>
            <v-sheet class="text-center" height="70vh" style="position:relative">
                <l-map ref="map" style="height: 100%; width: 100%">
                    <l-control position="topright">
                        <v-btn @click="mapOpen = false, combinationMarkers = null" fab dark small color="error">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </l-control>
                    <l-control-layers position="topleft"  ></l-control-layers>
                    <l-tile-layer v-for="tileProvider in leaflet.tileProviders"
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"
                    ></l-tile-layer>

                    <l-marker-cluster :options="{maxClusterRadius: 20, showCoverageOnHover: false}">
                        <l-marker v-for="task in taskMarkers" :key="task.marker_id"
                            :lat-lng="task.latLng"
                            :icon="task.icon">
                            <l-popup>
                                <table>
                                    <tr>
                                        <th colspan="2">
                                            <template v-if="task.pending">
                                                За обслужване на
                                                {{ task.formattedDate}}<br>
                                                {{ task.daysRemaining >= 0 ? `(След ${task.daysRemaining} ${daysWord(task.daysRemaining)})`
                                                    : ("(Пресрочено с " + Math.abs(task.daysRemaining) + ` ${daysWord(task.daysRemaining)})`)
                                                }}
                                            </template>
                                            <template v-else>
                                                Потвърдено за
                                                {{ task.formattedDate}}<br>
                                                {{ `(След ${task.daysRemaining} ${daysWord(task.daysRemaining)})` }}
                                            </template>
                                        </th>
                                    </tr>
                                    <tr><th>Клиент</th><td>{{ task.pending ? task.client.name : task.location.client.name }}</td></tr>
                                    <tr><th>Адрес</th><td>{{ task.pending ? task.address : task.location.address }}</td></tr>
                                </table>
                            </l-popup>
                        </l-marker>
                    </l-marker-cluster>
                </l-map>
                
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>


<script>
import { default as leaflet, defaultBounds } from '../maps/leaflet'
import { DivIcon, latLngBounds } from 'leaflet'
import moment from 'moment'
import api from '../webapi'
import * as util from '../util'
import geoDistance from '../maps/geo-distance'
import * as R from 'ramda'
import { paymentMethods, paymentDocuments } from './LocationEditor'

import VPending from './tasks/Pending'
import VDispatched from './tasks/Dispatched'
import VDispatchDialog from './tasks/DispatchDialog'

const DAYS_LIMIT = 30;
const MAX_DISTANCE = 1000;

export default {
    name: 'Tasks',
    data() {
        return {
            self: this,
            tab: 0,
            mapOpen: false,
            loading: true,
            searchPending: '',
            numDays: DAYS_LIMIT,
            maxDays: DAYS_LIMIT,
            maxDistance: MAX_DISTANCE,
            leaflet,
            departments: [],
            departmentsById: {},
            tasksPending: [],
            tasksDispatched: [],
            combinationMarkers: null,
            paymentMethods: util.itemsToByValue(paymentMethods),
            paymentDocuments: util.itemsToByValue(paymentDocuments),
            reloadInterval: null,
        }
    },
    computed: {
        filteredPendingTasks() {
            return this.tasksPending.filter(t =>
                t.daysRemaining <= this.numDays && (this.$root.department === null || this.$root.department == t.department_id)
            );
        },
        filteredDispatchedTasks() {
            return this.tasksDispatched.filter(t =>
                t.daysRemaining <= this.numDays && (this.$root.department === null || this.$root.department == t.location.department_id)
            );
        },
        filteredAllTasks() {
            return [...this.filteredPendingTasks, ...this.filteredDispatchedTasks];
        },
        taskMarkers() {
            return this.combinationMarkers || this.filteredAllTasks;
        },
        serviceCombinations() {
            const combs = {};

            const dispatchedMatIdsByDate = R.map(
                                R.compose(R.pluck('id'), R.flatten, R.pluck('mats')), // flatten and extract the mat ids
                                R.groupBy(R.prop('service_date'), this.tasksDispatched) // group dispatched tasks by service_date
                            );

            this.tasksPending.forEach(task => {
                const matIds = R.pluck('id', task.mats);
                const combinationTransducer = R.compose( // creates distance object for each combinaton of `task` and a dispatched task
                    // remove things we don't want to combine with
                    R.filter(d => //d.location.id != task.id // do not offer the same location
                        task.daysRemaining > 0 // do not combine for overdue tasks
                        && task.daysRemaining != d.daysRemaining // do not combine tasks on same date
                        && Math.abs(task.daysRemaining - d.daysRemaining) <= task.tolerance),

                    // make the distance object
                    R.map(d => ({
                        distance: geoDistance.between({lat: d.location.lat, lon: d.location.lng}, {lat: task.lat, lon: task.lng}),
                        dayOffset: d.daysRemaining - task.daysRemaining,
                        mats: R.without(dispatchedMatIdsByDate[d.service_date], matIds) // remove mats if they are serviced in any dispatched task on that date
                                .map( mId => R.find(R.propEq('id', mId), task.mats)),
                        target: d})),

                    // remove objects without mats
                    R.filter(t => t.mats.length),

                    // remove objects with distances higher than the max
                    R.filter(t => parseInt(t.distance.in_unit('m').toString()) < this.maxDistance)
                );

                const groupByDayOffsetReducer = (acc, t) => ((acc[t.dayOffset] || (acc[t.dayOffset] = [])).push(t), acc);

                const distancesGroupedByOffset = R.transduce(combinationTransducer, groupByDayOffsetReducer, {}, this.tasksDispatched);

                // leave only the minimal distance from each group
                const minDistances = R.values( R.map(a => a.reduce( (p, q) => p.distance.radians < q.distance.radians ? p : q), distancesGroupedByOffset) );

                // sort by absolute offset
                minDistances.sort((a, b) => Math.abs(a.dayOffset) - Math.abs(b.dayOffset) || a.dayOffset - b.dayOffset);
                
                combs[task.key] = minDistances;
            });
            return combs;
        }
    },
    mounted() {
        var days = window.localStorage.getItem('numDays');
        if (days !== null && !isNaN(days = parseInt(days)))
            this.numDays = days;

        var maxD = window.localStorage.getItem('maxDistance');
        if (maxD !== null && !isNaN(maxD = parseInt(maxD)))
            this.maxDistance = maxD;

        this.$refs.map.mapObject.setView([42.6,25.25], 7);

        this.reloadInterval = setInterval(this.reload.bind(this), 10*60*1000);
        this.reload();

    },
    beforeDestroy() {
        if (this.reloadInterval)
            clearInterval(this.reloadInterval);
    },
    watch: {
        numDays(value) {
            window.localStorage.setItem('numDays', value);
        },
        maxDistance(value) {
            window.localStorage.setItem('maxDistance', value);
        }
    },
    methods: {
        formatDate: util.formatDate,
        dispatch(locationId, mats, serviceDate) {
            this.$refs.dispatchDialog.show(locationId, mats, serviceDate);
        },
        showMap() {
            this.mapOpen = true;
            
            this.$nextTick(() => {
                //this.$refs.map.mapObject.fitBounds(defaultBounds);
                
                this.$refs.map.mapObject.invalidateSize()
            });
        },
        flyTo(...latLng) {
            this.showMap();
            this.$nextTick(() => {
                if (latLng.length == 1)
                    this.$refs.map.mapObject./*flyTo*/setView(latLng[0], 18);
                else
                    this.$refs.map.mapObject./*flyToBounds*/fitBounds(latLngBounds(latLng).pad(.2));
            });
        },
        processPendingTasks(pending, clients, matTypes) {
            const today = moment().startOf('day');
            const newp = {};

            pending.forEach(location => {
                location.latLng = [ location.lat, location.lng ];
                location.client = clients[location.client_id];
                location.fullAddress = `${location.city}, ${location.address}`;

                location.mats.forEach(m => {
                    const key = `${location.id}_${m.next_service}`;

                    var matTask;
                    if (!(key in newp)) {
                        matTask = newp[key] = R.clone(location);
                        matTask.key = key;
                        matTask.pending = true;
                        matTask.marker_id = 'p' + key;
                        matTask.mats = [];
                        matTask.serviceDate = m.next_service;
                        matTask.daysRemaining = moment(m.next_service).diff(today, 'days');
                        matTask.formattedDate = this.formatDate(m.next_service);
                        matTask.icon = new DivIcon.SVGIcon({
                            circleText: matTask.daysRemaining,
                            color: 'lightblue',
                            fillColor: 'blue',
                            fillOpacity: .9,
                        });
                        Object.defineProperty(matTask, 'combinations', {
                            enumerable: true,
                            get: () => this.serviceCombinations[key]
                        });
                    }
                    else
                       matTask = newp[key];
                    
                    m.type = matTypes[m.mat_type_id];
                    m.payment = `${this.paymentMethods[m.payment_method]}, ${this.paymentDocuments[m.payment_document]}`;
                    matTask.mats.push(m);
                });
                
            });
            return Object.values(newp);
        },
        processDispatchedTasks(tasks, matTypes) {
            const today = moment().startOf('day');

            // merge mats for same locations on same day
            tasks = Object.values(tasks.reduce((acc, t) => {
                const key = `${t.location_id}_${t.service_date}`;
                if (key in acc)
                    acc[key].mats.push(...t.mats);
                else {
                    t.key = key;
                    acc[key] = t;
                }
                return acc;
            }, {}));

            tasks.forEach(task => {
                task.marker_id = 'd' + task.key;
                task.pending = false;
                task.daysRemaining = moment(task.service_date).diff(today, 'days');
                task.fullAddress = `${task.location.address}, ${task.location.city}`;
                task.formattedDate = this.formatDate(task.service_date);
                task.latLng = [ task.location.lat, task.location.lng ];
                task.icon = new DivIcon.SVGIcon({
                    circleText: task.daysRemaining,
                    color: 'lightgreen',
                    fillColor: 'green',
                    fillOpacity: .9,
                });
                task.mats.forEach(m => {
                    m.type = matTypes[m.mat_type_id];
                    m.payment = `${this.paymentMethods[m.payment_method]}, ${this.paymentDocuments[m.payment_document]}`;
                });
            });
            return tasks;
        },
        processMatTypes(mt) {
            for (let i in mt) {
                let m = mt[i];
                m.full = `${m.type}, ${m.size}, ${m.period}`;
            }
        },
        daysWord(days) {
            return Math.abs(days) == 1 ? 'ден' : 'дни';
        },
        async reload() {
            const acLoading = api.autocomplete('departments');
            const clientsLoading = api.clients().then(util.objectsById);
            const matTypesLoading = api.matTypes().then(util.objectsById);
            const dispLoading = api.tasksDispatched(DAYS_LIMIT);
            const pendLoading = api.tasksPending(DAYS_LIMIT);

            const tp = await pendLoading;
            const td = await dispLoading;
            const cl = await clientsLoading;
            const mt = await matTypesLoading;

            this.processMatTypes(mt);

            this.tasksPending = Object.freeze(this.processPendingTasks(tp, cl, mt));
            this.tasksDispatched = Object.freeze(this.processDispatchedTasks(td, mt));
            
            const deps = (await acLoading).departments;
            this.departments = Object.freeze(util.makeDepartmentsSelect(deps));
            this.departmentsById = Object.freeze(util.itemsToByValue(deps));

            this.loading = false;
        },
    },
    components: {
        VPending,
        VDispatched,
        VDispatchDialog
    }
}
</script>   