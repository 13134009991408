var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-8" },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredClients,
          search: _vm.search,
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c("v-text-field", {
                      attrs: { label: "Търсене", clearable: "" },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c("v-select", {
                      staticClass: "mr-8",
                      staticStyle: { "max-width": "200px" },
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        label: "Отдел",
                        items: _vm.departments
                      },
                      model: {
                        value: _vm.$root.department,
                        callback: function($$v) {
                          _vm.$set(_vm.$root, "department", $$v)
                        },
                        expression: "$root.department"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          fab: "",
                          color: "error",
                          dark: "",
                          to: "/clients/new"
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-plus")])],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.department_id",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.departmentsById[item.department_id]) +
                    "\n        "
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      color: "primary",
                      title: "Редактиране",
                      to: "/clients/" + item.id
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-pencil")])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      color: "primary",
                      title: "Обекти",
                      to: "/clients/" + item.id + "/locations"
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-database-marker")])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      small: "",
                      icon: "",
                      color: "primary",
                      title: "Изтриване"
                    },
                    on: {
                      click: function($event) {
                        return _vm.remove(item.id)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-trash-can")])],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }