<template>
    <v-data-table
        class="dispatched-tasks"
        :headers="headers"
        :items="tasksComp.filteredDispatchedTasks"
        disable-pagination
        hide-default-footer
        :loading="tasksComp.loading"
        sort-by="daysRemaining"
        :sort-desc="false"
        :search="search"
        no-data-text="Няма потвърдени обекти"
    >
        <template v-slot:top>
            <VFilter :tasksComp="tasksComp" :search.sync="search"></VFilter>
        </template>

        <template v-slot:body="{ items, headers }">

                <tbody v-for="item in items" :key="item.key">
                    <tr v-for="(mat, i) in item.mats" :key="mat.id">
                        <template v-for="header in headers">
                            <td
                                v-if="i == 0 || header.matprop"
                                :key="header.value"
                                :rowspan="header.matprop ? '' : item.mats.length"
                                :class="{'mat-cell': header.matprop}"
                            >
                                <template v-if="header.value == 'actions'">
                                    <v-btn color="primary" small icon
                                        @click="tasksComp.flyTo(item.latLng)"
                                        title="Покажи на картата"><v-icon>mdi-map-marker</v-icon></v-btn>
                                </template>

                                <template v-else-if="header.value == 'serviceDate'">
                                    <span :class="{'red--text': item.daysRemaining < 0,
                                                    'orange--text': item.daysRemaining == 0,
                                                    'blue--text': item.daysRemaining == 1}">{{ item.formattedDate }}</span>
                                </template>

                                <template v-else-if="header.value == 'department_id'">
                                    {{ tasksComp.departmentsById[item.location.department_id] }}
                                </template>

                                <template v-else-if="header.value == 'payment'">
                                    {{ paymentMethods[mat.payment_method] }} / {{paymentDocuments[mat.payment_document] }}
                                </template>

                                <template v-else-if="header.matprop">
                                    {{ path(header.value.split('.'), {mat}) }}
                                </template>

                                <template v-else>
                                    {{ path(header.value.split('.'), item) }}
                                </template>


                            </td>
                        </template>
                    </tr>
                </tbody>

        </template>
    </v-data-table>
</template>

<style scoped>

.dispatched-tasks table td:not(:last-child),
.dispatched-tasks table td.mat-cell {
    border-right: solid 1px rgb(0, 0, 0, 0.12) !important;
}

.dispatched-tasks table td {
    border-bottom: solid 1px rgb(0, 0, 0, 0.12) !important;
}

.dispatched-tasks tbody:hover {
    background-color: #eee;
}
</style>

<script>
import VFilter from './Filter'
import { path } from 'ramda'

export default {
    props: [ 'tasksComp' ],
    data() {
        return {
            search: '',
        }
    },
    computed: {
        headers() {
            return [
                ...(this.$root.department === null ?
                    [ { value: 'department_id', text: 'Отдел', filterable: false } ]
                    : []
                ),
                ...[
                    { value: 'location.client.name', text: 'Клиент' },
                    { value: 'fullAddress', text: 'Адрес' },
                    { value: 'comment', text: 'Коментар за обекта', sortable: false},
                    { value: 'formattedDate', text: 'Дата', filterable: false},
                    { value: 'mat.type.code', text: 'Код', filterable: false, sortable: false, matprop: true},
                    { value: 'mat.type.full', text: 'Вид', filterable: false, sortable: false, matprop: true},
                    { value: 'mat.count', text: 'Брой', filterable: false, sortable: false, matprop: true},
                    { value: 'mat.color', text: 'Цвят', filterable: false, sortable: false, matprop: true},
                    { value: 'mat.comment', text: 'Забележка', filterable: false, sortable: false, matprop: true},
                    { value: 'actions', text: 'Действия', align: "right", sortable: false, filterable: false }
                ]
            ];
        }
    },
    methods: {
        path
    },
    components: {
        VFilter
    }
}
</script>