var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("VDispatchDialog", {
        ref: "dispatchDialog",
        attrs: { tasksComp: _vm.self },
        on: {
          dispatched: function($event) {
            ;(_vm.loading = true), _vm.reload()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { dark: "", grow: "", "background-color": "primary" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab",
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "warning",
                        inline: "",
                        content: _vm.filteredPendingTasks.length.toString()
                      }
                    },
                    [_vm._v("За обслужване")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "warning",
                        inline: "",
                        content: _vm.filteredDispatchedTasks.length.toString()
                      }
                    },
                    [_vm._v("Потвърдени")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              attrs: { touchless: "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [_c("VPending", { attrs: { tasksComp: _vm.self } })],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card-text",
                    [_c("VDispatched", { attrs: { tasksComp: _vm.self } })],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: {
            fab: "",
            fixed: "",
            bottom: "",
            right: "",
            color: "green",
            dark: ""
          },
          on: { click: _vm.showMap }
        },
        [_c("v-icon", [_vm._v("mdi-map")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            eager: "",
            "hide-overlay": "",
            persistent: "",
            "no-click-animation": ""
          },
          model: {
            value: _vm.mapOpen,
            callback: function($$v) {
              _vm.mapOpen = $$v
            },
            expression: "mapOpen"
          }
        },
        [
          _c(
            "v-sheet",
            {
              staticClass: "text-center",
              staticStyle: { position: "relative" },
              attrs: { height: "70vh" }
            },
            [
              _c(
                "l-map",
                { ref: "map", staticStyle: { height: "100%", width: "100%" } },
                [
                  _c(
                    "l-control",
                    { attrs: { position: "topright" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            dark: "",
                            small: "",
                            color: "error"
                          },
                          on: {
                            click: function($event) {
                              ;(_vm.mapOpen = false),
                                (_vm.combinationMarkers = null)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("l-control-layers", { attrs: { position: "topleft" } }),
                  _vm._v(" "),
                  _vm._l(_vm.leaflet.tileProviders, function(tileProvider) {
                    return _c("l-tile-layer", {
                      key: tileProvider.name,
                      attrs: {
                        name: tileProvider.name,
                        visible: tileProvider.visible,
                        url: tileProvider.url,
                        attribution: tileProvider.attribution,
                        "layer-type": "base"
                      }
                    })
                  }),
                  _vm._v(" "),
                  _c(
                    "l-marker-cluster",
                    {
                      attrs: {
                        options: {
                          maxClusterRadius: 20,
                          showCoverageOnHover: false
                        }
                      }
                    },
                    _vm._l(_vm.taskMarkers, function(task) {
                      return _c(
                        "l-marker",
                        {
                          key: task.marker_id,
                          attrs: { "lat-lng": task.latLng, icon: task.icon }
                        },
                        [
                          _c("l-popup", [
                            _c("table", [
                              _c("tr", [
                                _c(
                                  "th",
                                  { attrs: { colspan: "2" } },
                                  [
                                    task.pending
                                      ? [
                                          _vm._v(
                                            "\n                                            За обслужване на\n                                            " +
                                              _vm._s(task.formattedDate)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                task.daysRemaining >= 0
                                                  ? "(След " +
                                                      task.daysRemaining +
                                                      " " +
                                                      _vm.daysWord(
                                                        task.daysRemaining
                                                      ) +
                                                      ")"
                                                  : "(Пресрочено с " +
                                                      Math.abs(
                                                        task.daysRemaining
                                                      ) +
                                                      " " +
                                                      _vm.daysWord(
                                                        task.daysRemaining
                                                      ) +
                                                      ")"
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                                            Потвърдено за\n                                            " +
                                              _vm._s(task.formattedDate)
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                "(След " +
                                                  task.daysRemaining +
                                                  " " +
                                                  _vm.daysWord(
                                                    task.daysRemaining
                                                  ) +
                                                  ")"
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Клиент")]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      task.pending
                                        ? task.client.name
                                        : task.location.client.name
                                    )
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("Адрес")]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      task.pending
                                        ? task.address
                                        : task.location.address
                                    )
                                  )
                                ])
                              ])
                            ])
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }