import moment from 'moment'

export const DATE_FORMAT = 'D-MM-YYYY, ddd';
export const WEEKEND_AVOID_TYPES = Object.freeze({prev: 'prev', next: 'next'});
export const PERIOD_TYPES = Object.freeze({byDow: 'byDow', byDate: 'byDate'});
export const PAYMENT_METHODS = {transfer: 1, cash: 2}
export const PAYMENT_DOCUMENTS = {invoice: 1, receipt: 2}

function* monthlyPeriodDateGenerator(periodData, afterDate) {
    if (!periodData.days.length) {
        yield null;
        return;
    }
    // offsets in days to the next/prev workday relative to the weekend day
    const dayOffsets = {
        0: { [WEEKEND_AVOID_TYPES.next]: 1, [WEEKEND_AVOID_TYPES.prev]: -2}, // Sunday
        6: { [WEEKEND_AVOID_TYPES.next]: 2, [WEEKEND_AVOID_TYPES.prev]: -1}, // Staruday
    }
    const avoidWeekends = periodData.avoidWeekends;
    const avoidType = periodData.avoidType;
    const isWeekend = day => day == 0 || day == 6; // sunday or saturday
    const days = periodData.days.map(v => v).sort((a,b) => a - b);

    afterDate = afterDate.clone().startOf('day');
    const currDate = afterDate.clone();
    var lastYieldedDate = null;

    while(true) {
        for (let date of days) {
            currDate.date(date);

            let candidate = currDate.clone();
            if (avoidWeekends && isWeekend(currDate.day())) {
                const offset = dayOffsets[currDate.day()];
                // try moving the date until we get a date within the month
                // and not on weekend
                candidate.add( offset[avoidType], 'days');
                if (candidate.month() != currDate.month())
                    // workday falls into the next/prev month. Move in the other direction
                    (candidate = currDate.clone()).date(date).add( offset[avoidType == WEEKEND_AVOID_TYPES.next ? WEEKEND_AVOID_TYPES.prev : WEEKEND_AVOID_TYPES.next], 'days');
            }
            if (candidate.isSameOrAfter(afterDate) && (!lastYieldedDate || candidate.isAfter(lastYieldedDate))) {
                lastYieldedDate = candidate.clone();
                yield candidate;
            }
        }

        currDate.add(1, 'month');
    }
}

function* weeklyPeriodDateGenerator(periodData, afterDate) {
    if (!periodData.days.length) {
        yield null;
        return;
    }
    const days = periodData.days.map(v=>v).sort((a,b) => a - b);
    // use utc to avoid problems with daylight saving switches
    const after = moment.utc([afterDate.year(), afterDate.month(), afterDate.date()]);
    const base = moment.utc(0).isoWeekday(1).unix() + periodData.weekOffset * 7 * 24 * 3600; // some Monday around unix zero epoch + weekOffset as base for periodicity
    const thisMonday = after.clone().isoWeekday(1).unix();
    const periodLength = periodData.weekPeriod * 7 * 24 * 3600;
    const dt = moment.unix(base + Math.floor((thisMonday - base) / periodLength) * periodLength).utc();

    while (true) {
        for (var d of days) {
            dt.isoWeekday(d);
            if (dt.isSameOrAfter(after)) {
                yield moment([dt.year(), dt.month(), dt.date()]); // convert back to local time
            }
        }
        dt.add(periodData.weekPeriod, 'weeks')
    }
}

function periodDateGenerator(periodData, after) {
    return (periodData.type == PERIOD_TYPES.byDow ? weeklyPeriodDateGenerator : monthlyPeriodDateGenerator)(periodData, after);
}



export function findNextPeriodDate(periodData, after) {
    return periodDateGenerator(periodData, after).next().value;
}

export function isPeriodDate(date, periodData) {
    date = date.clone().startOf('day');
    return date.isSame( periodDateGenerator(periodData, date).next().value );
}

export function numServicesInMonth(periodData) {
    if (periodData.type == PERIOD_TYPES.byDow)
        return periodData.days.length * (5 - periodData.weekPeriod)
    else
        return periodData.days.length;
}

export function findClosestPeriodDate(period, date, after) {
    if (!after)
        after = moment();
    after = after.clone().startOf('day');

    let bestDate = null;
    let bestDiff = null;
    for (let sdate of periodDayGenerator(period, after)) {
        const diff = sdate.diff(date, 'days');
        if (diff == 0) // exact
            return sdate;
        if (diff < 0) {
            // keep saving the dates until we move after `date`
            bestDate = sdate;
            bestDiff = -diff; // make positive
        }
        else {
            // after `date`
            if (bestDate && bestDiff <= diff)
                return bestDiff; // previous date is closer than this
            return diff; // no previous date, so this is the best one
        }
    }
}


export function makeDepartmentsSelect(departments) {
    return [{value: null, text: 'Всички'}, ...departments];
}

export function itemsToByValue(items) {
    return items.reduce((acc, val) => (acc[val.value] = val.text, acc), {})
}

export function objectsById(objs) {
    return objs.reduce((acc, val) => (acc[val.id] = val, acc), {});
}

export function formatDate(date) {
    return moment(date).format(DATE_FORMAT);
}

export function formatDateDb(date) {
    return date.format(moment.HTML5_FMT.DATE);
}