var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-4 mx-auto",
      attrs: { "max-width": "600", loading: _vm.loading }
    },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(this.id ? "Редактиране на обект" : "Нов обект"))
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "card" } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.save($event)
                    }
                  }
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      label: "Клиент",
                      items: _vm.clients,
                      rules: [
                        function(t) {
                          return !!t || "Изберете клиент"
                        }
                      ]
                    },
                    model: {
                      value: _vm.location.client_id,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "client_id", $$v)
                      },
                      expression: "location.client_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: { label: "Отговорен отдел", items: _vm.departments },
                    model: {
                      value: _vm.location.department_id,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "department_id", $$v)
                      },
                      expression: "location.department_id"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-combobox", {
                    attrs: {
                      label: "Град",
                      rules: [
                        function(t) {
                          return !!t.trim() || "Изберете град"
                        }
                      ],
                      items: _vm.cities
                    },
                    model: {
                      value: _vm.location.city,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "city", $$v)
                      },
                      expression: "location.city"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      label: "Адрес",
                      rules: [
                        function(t) {
                          return !!t.trim() || "Въведете адрес"
                        }
                      ]
                    },
                    model: {
                      value: _vm.location.address,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "address", $$v)
                      },
                      expression: "location.address"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      label: "Координати",
                      "append-icon": "mdi-map-marker",
                      value: _vm.coordinates,
                      rules: [
                        function() {
                          return (
                            !!(_vm.location.lat && _vm.location.lng) ||
                            "Изберете координати"
                          )
                        }
                      ]
                    },
                    on: { click: _vm.openMap }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "96%" },
                      model: {
                        value: _vm.mapDialog,
                        callback: function($$v) {
                          _vm.mapDialog = $$v
                        },
                        expression: "mapDialog"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "d-flex align-start flex-column",
                          attrs: { height: "90vh" }
                        },
                        [
                          _c(
                            "l-map",
                            {
                              ref: "map",
                              staticStyle: { height: "100%", width: "100%" },
                              on: { click: _vm.addMarker }
                            },
                            [
                              _c(
                                "l-control",
                                { attrs: { position: "topright" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: !_vm.markerLatlng,
                                        fab: "",
                                        dark: "",
                                        small: "",
                                        color: "success"
                                      },
                                      on: { click: _vm.saveCoordinates }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-check")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        fab: "",
                                        dark: "",
                                        small: "",
                                        color: "error"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.mapDialog = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "l-control",
                                { attrs: { position: "bottomleft" } },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v(
                                          "\n                                       " +
                                            _vm._s(
                                              _vm.geocodesLoading
                                                ? "Търсене на адреси ..."
                                                : _vm.geocodes.length
                                                ? "Открити адреси:"
                                                : "Не са открити адреси"
                                            ) +
                                            "\n                                    "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(_vm.geocodes, function(g, i) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: i,
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoGeocode(g.coords)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-crosshairs-gps")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  domProps: {
                                                    textContent: _vm._s(g.text)
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("l-control-layers", {
                                attrs: { position: "topleft" }
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.leaflet.tileProviders, function(
                                tileProvider
                              ) {
                                return _c("l-tile-layer", {
                                  key: tileProvider.name,
                                  attrs: {
                                    name: tileProvider.name,
                                    visible: tileProvider.visible,
                                    url: tileProvider.url,
                                    attribution: tileProvider.attribution,
                                    "layer-type": "base"
                                  }
                                })
                              }),
                              _vm._v(" "),
                              _vm._l(_vm.geocodes, function(g, i) {
                                return _c("l-marker", {
                                  key: i,
                                  attrs: {
                                    "lat-lng": g.coords,
                                    icon: _vm.circleIcon
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.markerLatlng = g.coords
                                    }
                                  }
                                })
                              }),
                              _vm._v(" "),
                              _vm.markerLatlng
                                ? _c("l-marker", {
                                    attrs: { "lat-lng": _vm.markerLatlng }
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-switch", {
                    attrs: { label: "Активен" },
                    model: {
                      value: _vm.location.active,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "active", $$v)
                      },
                      expression: "location.active"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-text-field", {
                    attrs: {
                      label: "Толеранс за комбинации",
                      type: "number",
                      min: "0",
                      hint:
                        "±брой дни, за които ще се дава предложение за комбинация с друго обслужване",
                      rules: [
                        function(t) {
                          return (t !== "" && t >= 0) || "Въведете брой дни"
                        }
                      ]
                    },
                    model: {
                      value: _vm.location.tolerance,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "tolerance", $$v)
                      },
                      expression: "location.tolerance"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textarea", {
                    staticClass: "mt-8",
                    attrs: { label: "Забележка за обекта", rows: 2 },
                    model: {
                      value: _vm.location.comment,
                      callback: function($$v) {
                        _vm.$set(_vm.location, "comment", $$v)
                      },
                      expression: "location.comment"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "mt-4",
                      staticStyle: { position: "relative" }
                    },
                    [
                      _c(
                        "v-scale-transition",
                        { attrs: { group: "", tag: "div" } },
                        _vm._l(_vm.location.mats, function(mat, i) {
                          return _c(
                            "div",
                            { key: mat._id, staticClass: "mb-2" },
                            [
                              _c(
                                "v-card",
                                { staticClass: "yellow lighten-5" },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _vm._v(
                                        "\n                            Изтривалка " +
                                          _vm._s(i + 1) +
                                          "\n                            "
                                      ),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-scale-transition",
                                        { attrs: { origin: "center" } },
                                        [
                                          _vm.location.mats.length > 1
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$delete(
                                                        _vm.location.mats,
                                                        i
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-delete")
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          label: "Код",
                                          items: _vm.matTypeItems,
                                          rules: [
                                            function(v) {
                                              return !!v || "Изберете код"
                                            }
                                          ]
                                        },
                                        model: {
                                          value: mat.mat_type_id,
                                          callback: function($$v) {
                                            _vm.$set(mat, "mat_type_id", $$v)
                                          },
                                          expression: "mat.mat_type_id"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-combobox", {
                                        attrs: {
                                          label: "Цвят",
                                          rules: [
                                            function(t) {
                                              return (
                                                !!t.trim() || "Изберете цвят"
                                              )
                                            }
                                          ],
                                          items: _vm.matcolors
                                        },
                                        model: {
                                          value: mat.color,
                                          callback: function($$v) {
                                            _vm.$set(mat, "color", $$v)
                                          },
                                          expression: "mat.color"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Брой",
                                          type: "number",
                                          min: 1,
                                          rules: [
                                            function(v) {
                                              return (
                                                (v && v > 0) || "Въведете брой"
                                              )
                                            }
                                          ]
                                        },
                                        model: {
                                          value: mat.count,
                                          callback: function($$v) {
                                            _vm.$set(mat, "count", $$v)
                                          },
                                          expression: "mat.count"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("VPeriodInput", {
                                        attrs: { period: mat.period },
                                        on: {
                                          update: function($event) {
                                            return _vm.updateMatPeriod(
                                              mat,
                                              $event
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("VDateInput", {
                                        attrs: {
                                          label: "Следващо обслужване",
                                          min: _vm.today(),
                                          events: _vm.makeIsPeriodDate(mat),
                                          rules: [
                                            function(d) {
                                              return !!d || "Изберете дата"
                                            }
                                          ]
                                        },
                                        model: {
                                          value: mat.next_service,
                                          callback: function($$v) {
                                            _vm.$set(mat, "next_service", $$v)
                                          },
                                          expression: "mat.next_service"
                                        }
                                      }),
                                      _vm._v(" "),
                                      mat.period &&
                                      mat.period.days &&
                                      mat.period.days.length &&
                                      mat.next_service &&
                                      !_vm.isServiceDay(
                                        mat.next_service,
                                        mat.period
                                      )
                                        ? _c(
                                            "v-alert",
                                            { attrs: { type: "warning" } },
                                            [
                                              _vm._v(
                                                "\n                                Внимание: Избраната дата не принадлежи на периода на обслужване\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { label: "Начин на плащане" },
                                          model: {
                                            value: mat.payment_method,
                                            callback: function($$v) {
                                              _vm.$set(
                                                mat,
                                                "payment_method",
                                                $$v
                                              )
                                            },
                                            expression: "mat.payment_method"
                                          }
                                        },
                                        _vm._l(_vm.paymentMethods, function(r) {
                                          return _c("v-radio", {
                                            key: r.value,
                                            attrs: {
                                              value: r.value,
                                              label: r.text
                                            }
                                          })
                                        }),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { label: "Платежен документ" },
                                          model: {
                                            value: mat.payment_document,
                                            callback: function($$v) {
                                              _vm.$set(
                                                mat,
                                                "payment_document",
                                                $$v
                                              )
                                            },
                                            expression: "mat.payment_document"
                                          }
                                        },
                                        _vm._l(_vm.paymentDocuments, function(
                                          r
                                        ) {
                                          return _c("v-radio", {
                                            key: r.value,
                                            attrs: {
                                              value: r.value,
                                              label: r.text
                                            }
                                          })
                                        }),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-textarea", {
                                        staticClass: "mt-8",
                                        attrs: { label: "Забележка", rows: 2 },
                                        model: {
                                          value: mat.comment,
                                          callback: function($$v) {
                                            _vm.$set(mat, "comment", $$v)
                                          },
                                          expression: "mat.comment"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            absolute: "",
                            bottom: "",
                            right: "",
                            small: "",
                            color: "error"
                          },
                          on: { click: _vm.addMat }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading
        ? _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: { color: "error" },
                  on: { click: _vm.back }
                },
                [_vm._v("Откажи")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "success", loading: _vm.saving },
                  on: { click: _vm.save }
                },
                [_vm._v("Запиши")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }