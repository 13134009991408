<template>
    <v-card max-width="600" class="pa-4 mx-auto" :loading="loading">
        <v-card-title>{{ this.id ? 'Редактиране на клиент' : 'Нов клиент'}}</v-card-title>
        <v-skeleton-loader type="card" v-if="loading" />
        <v-card-text v-if="!loading">
            <v-form ref="form" @submit.prevent="save">
                <v-text-field label="Име" v-model="client.name" :rules="[t => !!t.trim() || 'Въведете име']" />

                <v-select label="Отговорен отдел" v-model="client.department_id" :items="departments" />
            </v-form>
        </v-card-text>
        <v-card-actions v-if="!loading">
            <v-btn class="mr-4" color="error" @click="back">Откажи</v-btn>
            <v-btn color="success" @click="save" :loading="saving">Запиши</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import api from '../webapi'
import * as R from 'ramda'
import * as util from '../util'

export default {
    props: [ 'id' ],
    data() {
        return {
            departments: [],
            loading: true,
            saving: false,
            client: {
                name: '',
                department_id: 1,
            }  
        };
    },
    methods: {
        async save() {
            if (this.$refs.form.validate()) {
                this.saving = true;
                await api.clientSave(this.client);
                this.back();
            }
        },
        back() {
            this.$router.push(this.$router.lastRoute || '/clients');
        }
    },
    async mounted() {
        this.departments = (await api.autocomplete('departments')).departments;
        if (this.id) {
            Object.assign(this.client, await api.client(this.id));
        }
        this.loading = false;
    },
}
</script>