var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", lg: "3" } },
        [
          _c("v-text-field", {
            attrs: {
              "hide-details": "",
              "single-line": "",
              dense: "",
              "prepend-icon": "mdi-magnify",
              clearable: "",
              value: _vm.search
            },
            on: {
              input: function($event) {
                return _vm.$emit("update:search", $event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "6", sm: "3", lg: "2" } },
        [
          _c("v-select", {
            staticStyle: { "max-width": "200px" },
            attrs: {
              dense: "",
              "hide-details": "",
              label: "Отдел",
              items: _vm.tasksComp.departments
            },
            model: {
              value: _vm.$root.department,
              callback: function($$v) {
                _vm.$set(_vm.$root, "department", $$v)
              },
              expression: "$root.department"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.distance
        ? _c(
            "v-col",
            { attrs: { cols: "6", sm: "3", lg: "2" } },
            [
              _c("v-text-field", {
                attrs: {
                  type: "number",
                  "hide-details": "",
                  dense: "",
                  "prepend-icon": "mdi-map-marker-distance",
                  label: "Максимално разстояние за комбинации",
                  min: "0"
                },
                model: {
                  value: _vm.tasksComp.maxDistance,
                  callback: function($$v) {
                    _vm.$set(_vm.tasksComp, "maxDistance", $$v)
                  },
                  expression: "tasksComp.maxDistance"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12", lg: "5" } },
        [
          _c("v-slider", {
            attrs: {
              min: 0,
              dense: "",
              "single-line": "",
              "hide-details": "",
              max: _vm.tasksComp.maxDays,
              label:
                "Показвай следващите " +
                _vm.tasksComp.numDays +
                " " +
                _vm.tasksComp.daysWord(_vm.tasksComp.numDays)
            },
            model: {
              value: _vm.tasksComp.numDays,
              callback: function($$v) {
                _vm.$set(_vm.tasksComp, "numDays", $$v)
              },
              expression: "tasksComp.numDays"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }