<template>
    <v-dialog max-width="600" v-model="isShown">
        <v-card :loading="loading" class="pa-4">
            <v-card-title>Oбслужване на обект</v-card-title>

            <v-skeleton-loader type="card" v-if="loading" />
            <v-form v-if="!loading" @submit.prevent="dispatch">
                <v-card-text >
                        <VDateInput
                            label="Дата на обслужване"
                            v-model="serviceDate"
                            :min="today()"></VDateInput>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="error"
                        @click="isShown = false">Откажи</v-btn>
                    <v-btn color="success"
                        :loading="saving"
                        @click="dispatch">Потвърди</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>


<script>
import VDateInput from '../inputs/DateInput'
import * as util from '../../util'
import moment from 'moment'
import api from '../../webapi'
import * as R from 'ramda'

export default {
    data() {
        return {
            isShown: false,
            serviceDate: '',
            nextServiceDate: '',
            locationId: null,
            mats: [],
            loading: true,
            saving: false,
        };
    },
    methods: {
        async show(locationId, mats, serviceDate) {
            this.loading = true;
            this.saving = false;
            this.isShown = true;
            this.locationId = locationId;
            this.mats = mats;
            this.serviceDate = moment(serviceDate).isBefore(moment().startOf('day')) ?
                this.today() : serviceDate;
            this.loading = false;
        },
        today() {
            return util.formatDateDb(moment());
        },

        async dispatch() {
            this.saving = true;

            const afterActualServiceDate = moment(this.serviceDate).add(1, 'days');
            const matsNextServiceDates = this.mats.map( m => {
                    const afterScheduledServiceDate = moment(m.next_service).add(1, 'days');
                    // if the actual service date is before the normally scheduled servie date
                    // this means we are performing the service earlier, so if we find the next
                    // service date agains this earlier date, we'll get the same date as the normally
                    // scheduled. That's why we look for next service date after the later of both dates
                    const after = afterActualServiceDate.isAfter(afterScheduledServiceDate) ? afterActualServiceDate : afterScheduledServiceDate;
                    return {
                        id: m.id,
                        next_service: util.formatDateDb( util.findNextPeriodDate(m.period, after) )
                    };
                }
            );
            
            await api.locationService(this.locationId, this.serviceDate, matsNextServiceDates);
            
            this.saving = false;
            this.isShown = false;
            this.$emit('dispatched');
        }
    },
    components: {
        VDateInput
    }
}
</script>